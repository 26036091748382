import DivisionReader from '@src/core/infrastructure/Division/DivisionReader';
import HttpClient from '@src/core/infrastructure/http';

class ApiDivisionReader implements DivisionReader {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async fetchBySubSub(query: string): Promise<string> {
    try {
      const response = await this.httpClient.get(
        `${this.baseUrl}/tracking/divisions/category/${query}`
      );

      return JSON.stringify(response.data());
    } catch (err) {
      throw err;
    }
  }
}

export default ApiDivisionReader;
