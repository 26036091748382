import React from 'react';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import ButtonGroup from '@src/ui/apps/ServiceRequest/ButtonGroup';
import NextButton from '@src/ui/apps/ServiceRequest/NextButton';

type MiddleStepFormControlsProps = {
  goBack: () => void;
  showDesignVariant?: boolean;
};

export function MiddleStepFormControls({
  goBack,
  showDesignVariant,
}: MiddleStepFormControlsProps): React.ReactElement {
  return (
    <ButtonGroup showDesignVariant={showDesignVariant}>
      <BackButton goBack={goBack} showDesignVariant={showDesignVariant} />
      <NextButton showDesignVariant={showDesignVariant} />
    </ButtonGroup>
  );
}

type MiddleNextStepFormControlsProps = {
  showDesignVariant?: boolean;
};

export function MiddleNextStepFormControls({
  showDesignVariant,
}: MiddleNextStepFormControlsProps): React.ReactElement {
  return (
    <ButtonGroup showDesignVariant={showDesignVariant}>
      <NextButton showDesignVariant={showDesignVariant} />
    </ButtonGroup>
  );
}
