import React from 'react';
import AsyncAutocomplete, { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import EmailDomainAutocompleteOption from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/EmailDomainAutocompleteOption';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import FetchAllEmailDomainsFactory from '@src/core/useCases/EmailDomain/FetchAllEmailDomainsFactory';

type EmailDomainAutocompleteProps = {
  onChange: (option?: AutocompleteOption, query?: string) => void;
  onBlur: (e: Event) => void;
  inputStatus: InputStatus;
  defaultSelected?: AutocompleteOption;
  placeholder?: string;
  showDesignVariant?: boolean;
};

function EmailDomainAutocomplete({
  onChange,
  onBlur,
  inputStatus,
  defaultSelected,
  placeholder,
  showDesignVariant,
}: EmailDomainAutocompleteProps): React.ReactElement {
  const onSearchHandler = async (query: string): Promise<EmailDomainAutocompleteOption[]> => {
    const atSignIndex = query.indexOf('@');
    if (atSignIndex === -1) {
      return [];
    }
    const emailName = query.slice(0, atSignIndex);
    const emailDomain = query.slice(atSignIndex + 1, -1);
    const domains = FetchAllEmailDomainsFactory.create().execute();

    const autocompleteOptions: EmailDomainAutocompleteOption[] = domains
      .filter((domain) => {
        return domain.includes(emailDomain);
      })
      .map((domain) => new EmailDomainAutocompleteOption(`${emailName}@${domain}`));
    return Promise.resolve(autocompleteOptions);
  };

  return (
    <AsyncAutocomplete
      filterBy={(option, props) => {
        return true;
      }}
      id="emailDomainAutocompleteSearcher"
      onChange={onChange}
      onSearch={onSearchHandler}
      onBlur={onBlur}
      inputStatus={inputStatus}
      inputModeV="email"
      renderMenu={(results, menuProps, state) => {
        if (!results.length) {
          return null;
        }
        return (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                {result.label(state.text)}
              </MenuItem>
            ))}
          </Menu>
        );
      }}
      defaultSelected={defaultSelected}
      placeholder={placeholder}
      showDesignVariant={showDesignVariant}
    />
  );
}

export default EmailDomainAutocomplete;
