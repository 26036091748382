import React from 'react';
import classNames from 'classnames';
import styles from './ButtonGroup.module.scss';

type ButtonGroupProps = {
  className?: string;
  showDesignVariant?: boolean;
};

function ButtonGroup(props: React.PropsWithChildren<ButtonGroupProps>): React.ReactElement {
  return (
    <div
      className={classNames([styles['button-group'], props.className], {
        [styles['button-group--showDesignVariant']]: props.showDesignVariant,
      })}
    >
      {props.children}
    </div>
  );
}

export default ButtonGroup;
