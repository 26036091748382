import React, { ReactChild, ReactElement } from 'react';
import Alert, { AlertSize, AlertTypes } from '@src/ui/components/Alert/Alert';

type ValidationWarnigProps = {
  children: ReactChild;
};

const ValidationWarning = (props: ValidationWarnigProps): ReactElement => (
  <Alert variant={AlertTypes.WARNING} size={AlertSize.SMALL} icon={null}>
    <span className="warning">{props.children}</span>
  </Alert>
);

export default ValidationWarning;
