import React, { FunctionComponent, useEffect, useRef } from 'react';
import styles from './ButtonLabel.module.scss';

type ButtonLabelProps = {
  children: React.ReactNode;
  className?: string;
  showDesignVariant?: boolean;
};

const ButtonLabel: FunctionComponent<ButtonLabelProps> = ({
  children,
  className,
  showDesignVariant,
}: ButtonLabelProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!showDesignVariant) {
      return;
    }

    const container = containerRef.current;
    if (!container) return;

    const inputs = container.querySelectorAll<HTMLInputElement>(
      'input[type="radio"], input[type="checkbox"]'
    );

    const updateContainerClass = () => {
      inputs.forEach((input) => {
        if (input.checked) {
          container.classList.add(styles.checkedContainer);
        } else {
          container.classList.remove(styles.checkedContainer);
        }
      });
    };

    updateContainerClass();

    inputs.forEach((input) => {
      input.addEventListener('change', updateContainerClass);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('change', updateContainerClass);
      });
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`${className || ''} ${styles.buttonLabel} ${
        showDesignVariant ? styles.showDesignVariant : ''
      }`}
    >
      {children}
    </div>
  );
};

export default ButtonLabel;
