import Category from '@src/core/domain/Categories/Category';
import Location from '@src/core/domain/Locations/Location';
import { EventInterface } from '@src/core/domain/Events/EventInterface';
import Question from '@src/core/domain/Questions/Question';
import ServiceRequest from '@src/core/domain/ServiceRequest/ServiceRequest';
import { CreateServiceRequestSuccess } from '@src/core/useCases/ServiceRequest/CreateServiceRequest';

class JobTypeStepRendered implements EventInterface {
  eventName = 'JobTypeStepRendered';

  static get eventName(): string {
    return 'JobTypeStepRendered';
  }
}

class CategorySelected implements EventInterface {
  eventName = 'CategorySelected';

  static get eventName(): string {
    return 'CategorySelected';
  }

  constructor(readonly category: Category) {}
}

class JobTypeSelected implements EventInterface {
  eventName = 'JobTypeSelected';

  static get eventName(): string {
    return 'JobTypeSelected';
  }

  constructor(readonly category: Category, readonly jobType: Category) {}
}

class LocationStepRendered implements EventInterface {
  eventName = 'LocationStepRendered';

  static get eventName(): string {
    return 'LocationStepRendered';
  }

  constructor(readonly jobTypeId: string | undefined, readonly postalCode: string | undefined) {}
}

class PostalCodeLocationSubmitted implements EventInterface {
  eventName = 'PostalCodeLocationSubmitted';

  static get eventName(): string {
    return 'PostalCodeLocationSubmitted';
  }

  constructor(readonly postalCode: string) {}
}

class LocationWithParentSubmitted implements EventInterface {
  eventName = 'LocationWithParentSubmitted';

  static get eventName(): string {
    return 'LocationWithParentSubmitted';
  }

  constructor(readonly firstLevelLocation: Location, readonly secondLevelLocation: Location) {}
}

class HOExperiencePreferenceStepRendered implements EventInterface {
  eventName = 'HOExperiencePreferenceStepRendered';

  static get eventName(): string {
    return 'HOExperiencePreferenceStepRendered';
  }

  constructor(readonly order?: string) {}
}

class HOExperiencePreferenceClickedDirectory implements EventInterface {
  eventName = 'HOExperiencePreferenceClickedDirectory';

  static get eventName(): string {
    return 'HOExperiencePreferenceClickedDirectory';
  }
}

class HOExperiencePreferenceClickedReject implements EventInterface {
  eventName = 'HOExperiencePreferenceClickedReject';

  static get eventName(): string {
    return 'HOExperiencePreferenceClickedReject';
  }
}

class QuestionsLoaded implements EventInterface {
  eventName = 'QuestionsLoaded';

  static get eventName(): string {
    return 'QuestionsLoaded';
  }

  constructor(readonly questions: Question[]) {}
}

class QuestionStepRendered implements EventInterface {
  eventName = 'QuestionStepRendered';

  static get eventName(): string {
    return 'QuestionStepRendered';
  }

  constructor(readonly question: Question) {}
}

class SharePrivateServiceRequestStepRendered implements EventInterface {
  eventName = 'SharePrivateServiceRequestStepRendered';

  static get eventName(): string {
    return 'SharePrivateServiceRequestStepRendered';
  }
}

class SharePrivateServiceRequestClickedShare implements EventInterface {
  eventName = 'SharePrivateServiceRequestClickedShare';

  static get eventName(): string {
    return 'SharePrivateServiceRequestClickedShare';
  }
}

class SharePrivateServiceRequestClickedReject implements EventInterface {
  eventName = 'SharePrivateServiceRequestClickedReject';

  static get eventName(): string {
    return 'SharePrivateServiceRequestClickedReject';
  }
}

class DescriptionStepRendered implements EventInterface {
  eventName = 'DescriptionStepRendered';

  static get eventName(): string {
    return 'DescriptionStepRendered';
  }
}

class ImageUploadStarted implements EventInterface {
  eventName = 'ImageUploadStarted';

  static get eventName(): string {
    return 'ImageUploadStarted';
  }

  constructor(readonly fileSizeInBytes: number) {}
}

class ImageUploadEnded implements EventInterface {
  eventName = 'ImageUploadEnded';

  static get eventName(): string {
    return 'ImageUploadEnded';
  }

  constructor(readonly fileSizeInBytes: number) {}
}

class ContactDataStepRendered implements EventInterface {
  eventName = 'ContactDataStepRendered';

  static get eventName(): string {
    return 'ContactDataStepRendered';
  }
}

class ServiceRequestFormSubmitted implements EventInterface {
  eventName = 'ServiceRequestFormSubmitted';

  static get eventName(): string {
    return 'ServiceRequestFormSubmitted';
  }
}

class ServiceRequestCreated implements EventInterface {
  eventName = 'ServiceRequestCreated';

  static get eventName(): string {
    return 'ServiceRequestCreated';
  }

  constructor(
    readonly serviceRequest: ServiceRequest,
    readonly createServiceRequestSuccess: CreateServiceRequestSuccess
  ) {}
}

class ServiceRequestCanceled implements EventInterface {
  eventName = 'ServiceRequestCanceled';

  static get eventName(): string {
    return 'ServiceRequestCanceled';
  }
}

class sessionIdCreated implements EventInterface {
  eventName = 'sessionIdCreated';

  static get eventName(): string {
    return 'sessionIdCreated';
  }

  constructor(readonly sessionId: string) {}
}

class SearchServiceRequestCategory implements EventInterface {
  eventName = 'SearchServiceRequestCategory';

  static get eventName(): string {
    return 'SearchServiceRequestCategory';
  }

  constructor(readonly timeoutSearch: number, readonly searchQuery: string) {}
}

class ContactStepErrors implements EventInterface {
  eventName = 'ContactStepErrors';

  static get eventName(): string {
    return 'ContactStepErrors';
  }
  constructor(readonly errors: string[]) {}
}

class ServiceRequestType implements EventInterface {
  eventName = 'ServiceRequestType';

  static get eventName(): string {
    return 'ServiceRequestType';
  }
  constructor(readonly serviceRequestType: string, readonly remove: boolean = false) {}
}

class jobTypeAutocompleteVisible implements EventInterface {
  eventName = 'jobTypeAutocompleteVisible';

  static get eventName(): string {
    return 'jobTypeAutocompleteVisible';
  }
}

class jobTypeAutocompleteHidden implements EventInterface {
  eventName = 'jobTypeAutocompleteHidden';

  static get eventName(): string {
    return 'jobTypeAutocompleteHidden';
  }
}

export {
  JobTypeStepRendered,
  CategorySelected,
  JobTypeSelected,
  LocationStepRendered,
  PostalCodeLocationSubmitted,
  LocationWithParentSubmitted,
  HOExperiencePreferenceStepRendered,
  HOExperiencePreferenceClickedDirectory,
  HOExperiencePreferenceClickedReject,
  QuestionsLoaded,
  QuestionStepRendered,
  SharePrivateServiceRequestStepRendered,
  SharePrivateServiceRequestClickedShare,
  SharePrivateServiceRequestClickedReject,
  DescriptionStepRendered,
  ImageUploadStarted,
  ImageUploadEnded,
  ContactDataStepRendered,
  ServiceRequestFormSubmitted,
  ServiceRequestCreated,
  ServiceRequestCanceled,
  sessionIdCreated,
  SearchServiceRequestCategory,
  ContactStepErrors,
  ServiceRequestType,
  jobTypeAutocompleteVisible,
  jobTypeAutocompleteHidden,
};
