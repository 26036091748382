import React from 'react';
import { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import { Highlighter } from 'react-bootstrap-typeahead';

class EmailDomainAutocompleteOption implements AutocompleteOption {
  readonly name: string;

  constructor(name: string) {
    this.name = name;
  }
  label(search = ''): React.ReactElement {
    return <Highlighter search={search}>{this.name}</Highlighter>;
  }

  labelKey(): string {
    return this.name;
  }

  value(): string {
    return this.name;
  }
}

export default EmailDomainAutocompleteOption;
