import Factory from '@src/core/Factory';
import SearchState from '@src/core/useCases/State/SearchState';

class SearchStateFactory {
  private static _instance: SearchState | undefined;

  static create(): SearchState {
    if (this._instance === undefined) {
      this._instance = new SearchState(Factory.newStateRepository());
    }

    return this._instance;
  }

  static setInstance(instance: SearchState): void {
    this._instance = instance;
  }
}

export default SearchStateFactory;
