import {
  LocationStepRendered,
  LocationWithParentSubmitted,
  PostalCodeLocationSubmitted,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import React, { useEffect, useState } from 'react';

import { FormData } from '@src/ui/apps/ServiceRequest/useServiceRequestForm';
import { FormattedMessage } from 'react-intl';
import { LocationSearchAutocompleteData } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationSearchAutocomplete';
import { LocationSelectInputData } from './LocationSelectInput';
import { PostalCodeData } from './PostalCodeField';
import { ServiceRequestFormContext } from '../../ServiceRequestFormContext';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { getLocalizedService } from '@src/ui/helpers/localization';
import styles from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep.module.scss';
import { useForm } from 'react-hook-form';

export type LocationStepData = PostalCodeData &
  LocationSelectInputData &
  LocationSearchAutocompleteData;
export type LocationStepRenderProps = {
  setEvent?: (event: unknown) => void;
  form: UseFormMethods<LocationStepData>;
  formData: Partial<FormData>;
  showDesignVariant?: boolean;
};

export type LocationStepProps = {
  formControls?: React.ReactElement;
  changeJobTypeLink?: React.ReactNode;
  showDesignVariant?: boolean;
};

function LocationStep({
  changeJobTypeLink,
  formControls,
  showDesignVariant,
}: LocationStepProps): React.ReactElement {
  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const form = useForm<LocationStepData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const LocationStepRenderer = getLocalizedService<
    React.FunctionComponent<LocationStepRenderProps>
  >('LocationStepRenderer');
  const [event, setEvent] = useState<unknown>(undefined);
  useEffect(() => {
    eventBusSingleton.fireEvent(new LocationStepRendered(formData.jobTypeId, formData.postalCode));
  }, []);

  const wrappedOnSubmit = (locationStepData: LocationStepData) => {
    if (locationStepData['postalCode']) {
      eventBusSingleton.fireEvent(new PostalCodeLocationSubmitted(locationStepData['postalCode']));
    }

    if (event) {
      eventBusSingleton.fireEvent(event as LocationWithParentSubmitted);
    }

    handleStepCompleted(locationStepData);
  };

  const stepTitleClass = `step-title${showDesignVariant ? ' step-title--showDesignVariant' : ''}`;

  return (
    <>
      <StepTitle className={stepTitleClass} data-testid="step-title">
        {!showDesignVariant ? (
          <FormattedMessage
            id="serviceRequestForm.locationStep.header"
            defaultMessage="¿Para qué zona necesitas los presupuestos de {categoryName}?"
            description="Título del paso para escoger la localidad donde realizar el tipo de trabajo seleccionado"
            values={{ categoryName: formData.categoryName }}
          />
        ) : (
          <FormattedMessage
            id="serviceRequestForm.locationStep.newDesign.header"
            defaultMessage="¿Para qué zona necesitas los presupuestos de {jobTypeName}?"
            description="Título del paso para escoger la localidad donde realizar el tipo de trabajo seleccionado para el nuevo diseño"
            values={{
              jobTypeName:
                formData.jobTypeName && formData.jobTypeName !== ''
                  ? formData.jobTypeName
                  : formData.categoryName,
            }}
          />
        )}
      </StepTitle>
      {!showDesignVariant && changeJobTypeLink}
      <form onSubmit={form.handleSubmit(wrappedOnSubmit)} className={styles.LocationStep}>
        <LocationStepRenderer
          form={form}
          formData={formData}
          setEvent={setEvent}
          showDesignVariant={showDesignVariant}
        />
        {formControls}
      </form>
    </>
  );
}

export default LocationStep;
