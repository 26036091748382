import { useReducer } from 'react';
import UploadedPhoto from '@src/core/domain/Photos/UploadedPhoto';
import UploadPhotoFactory from '@src/core/useCases/Photos/UploadPhotoFactory';
import { MAX_ALLOWED_PHOTOS_UPLOADED } from '@src/ui/apps/ServiceRequest/Steps/DescriptionStep/DescriptionStep';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  ImageUploadEnded,
  ImageUploadStarted,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';

export type PhotoUploadState = {
  uploading: UploadedPhoto[];
  completed: UploadedPhoto[];
  errors: Error[];
};

export const emptyPhotoUploadState = {
  completed: [],
  uploading: [],
  errors: [],
};

export type uploadFiles = (droppedFiles: File[]) => void;
export type removeFile = (photoToDelete: UploadedPhoto) => void;

export type usePhotoUploadMethods = {
  onFileAdded: uploadFiles;
  removeFile: removeFile;
  uploads: PhotoUploadState;
};

const ADD_UPLOAD_ACTION = 'ADD_UPLOADS';
const REMOVE_UPLOAD_ACTION = 'REMOVE_UPLOAD';
const UPLOAD_COMPLETED_EVENT = 'UPLOAD_COMPLETED';
const UPLOAD_FAILED_EVENT = 'UPLOAD_FAILED';

export type UploadActions =
  | { type: 'ADD_UPLOADS'; files: UploadedPhoto[] }
  | { type: 'REMOVE_UPLOAD'; file: UploadedPhoto }
  | { type: 'UPLOAD_COMPLETED'; file: UploadedPhoto }
  | { type: 'UPLOAD_FAILED'; file: UploadedPhoto; error: Error };

const uploadedPhotosWithoutElement = (list: UploadedPhoto[], item: UploadedPhoto) =>
  list.filter((file) => file.file !== item.file);

const uploadReducer = (state: PhotoUploadState, action: UploadActions): PhotoUploadState => {
  switch (action.type) {
    case ADD_UPLOAD_ACTION:
      return {
        ...state,
        uploading: [...state.uploading, ...action.files],
        errors: [],
      };

    case UPLOAD_COMPLETED_EVENT:
      return {
        ...state,
        completed: [...state.completed, action.file],
        uploading: uploadedPhotosWithoutElement(state.uploading, action.file),
      };

    case REMOVE_UPLOAD_ACTION:
      return {
        ...state,
        completed: uploadedPhotosWithoutElement(state.completed, action.file),
      };

    case UPLOAD_FAILED_EVENT:
      return {
        ...state,
        uploading: uploadedPhotosWithoutElement(state.uploading, action.file),
        errors: [...state.errors, action.error],
      };
  }
};

const fileToUploadedPhoto = (file: File) => new UploadedPhoto('', URL.createObjectURL(file), file);

export function usePhotoUpload(): usePhotoUploadMethods {
  const [uploads, dispatch] = useReducer(uploadReducer, {
    uploading: [],
    completed: [],
    errors: [],
  });

  const onFileAdded = (droppedFiles: File[]): void => {
    const remainingUploads =
      MAX_ALLOWED_PHOTOS_UPLOADED - (uploads.completed.length + uploads.uploading.length);
    if (!remainingUploads) return;

    const slicedFiles = droppedFiles.slice(0, remainingUploads);

    dispatch({ type: ADD_UPLOAD_ACTION, files: slicedFiles.map(fileToUploadedPhoto) });

    slicedFiles.forEach((file) => {
      eventBusSingleton.fireEvent(new ImageUploadStarted(file.size));
      UploadPhotoFactory.create()
        .execute(file)
        .then((photo) => {
          eventBusSingleton.fireEvent(new ImageUploadEnded(file.size));
          dispatch({ type: UPLOAD_COMPLETED_EVENT, file: photo });
        })
        .catch((error: Error) => {
          dispatch({ type: UPLOAD_FAILED_EVENT, file: fileToUploadedPhoto(file), error: error });
        });
    });
  };

  const removeFile = (photoToDelete: UploadedPhoto): void => {
    dispatch({ type: REMOVE_UPLOAD_ACTION, file: photoToDelete });
  };

  return {
    onFileAdded,
    removeFile,
    uploads,
  };
}

export default usePhotoUpload;
