import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default function FreeAndWithoutObligation(): React.ReactElement {
  return (
    <p
      data-testid="footerText"
      className={styles['serviceRequestForm__free-text']}
      data-vwo="footerText"
    >
      <FormattedMessage
        id="serviceRequestForm.footer"
        defaultMessage="Gratis y sin compromiso"
        description="Texto que aparece en la parte inferior del formulario durante todos los pasos"
      />
    </p>
  );
}
