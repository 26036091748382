import StateRepositoryInterface from '@src/core/domain/State/StateRepositoryInterface';
import State from '@src/core/domain/State/State';

class SearchState {
  constructor(private readonly repository: StateRepositoryInterface) {}

  execute(searchCode: string): Promise<State> {
    return this.repository.fetchByPostalCode(searchCode);
  }
}

export default SearchState;
