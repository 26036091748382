import SearchDivisions from '@src/core/useCases/Divisions/SearchDivisions';
import Factory from '@src/core/Factory';

class SearchDivisionsFactory {
  private static _instance: SearchDivisions | undefined;

  static create(): SearchDivisions {
    if (this._instance === undefined) {
      this._instance = new SearchDivisions(Factory.newDivisionRepository());
    }

    return this._instance;
  }

  static setInstance(instance: SearchDivisions): void {
    this._instance = instance;
  }
}

export default SearchDivisionsFactory;
