import React from 'react';
import styles from './ProgressBar.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

type ProgressBarProps = {
  progress: number;
  currentStep?: number;
  className?: string;
  showDesignVariant?: boolean;
};

function ProgressBar({
  progress,
  currentStep,
  className,
  showDesignVariant,
}: ProgressBarProps): React.ReactElement {
  let width = Math.round(progress * 100);

  switch (showDesignVariant) {
    case currentStep === 1:
      width = 10;
      break;
    case currentStep === 2:
      width = 20;
      break;
  }

  return (
    <div className={className} data-testid="progressBar">
      <div
        className={classNames(styles.ProgressBar, {
          [styles['ProgressBar--showDesignVariant']]: showDesignVariant,
        })}
      >
        <div
          className={classNames(styles.ProgressBar__inner, 'progress-bar', {
            [styles['ProgressBar__inner--showDesignVariant']]: showDesignVariant,
          })}
          style={{ width: `${width}%` }}
          data-progress={width}
        ></div>
      </div>
      {showDesignVariant && (
        <div className={styles.ProgressBar__NewDesignProgressBar__progressText}>
          <FormattedMessage
            id="serviceRequestForm.newDesignProgressBar.header"
            defaultMessage="{width}% completado"
            description="% progreso en el formulario"
            values={{ width }}
          />
        </div>
      )}
    </div>
  );
}

export default ProgressBar;
