// components
import React from 'react';
// guide components
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import { ServiceRequestFormProps } from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
// infrastructure
import useServiceRequestForm from '@src/ui/apps/ServiceRequest/useServiceRequestForm';
import ServiceRequestFormRenderer from './ServiceRequestFormRenderer';
import { FormattedMessage } from 'react-intl';

function ServiceRequestForm({
  configuration,
  storage,
  stepToShow = 1,
  formData,
  FormRenderer = ServiceRequestFormRenderer,
}: ServiceRequestFormProps): React.ReactElement {
  const {
    state,
    handleStepCompleted,
    handleSubmit,
    goBackHandler,
    goFirstHandler,
    uploads,
    onFileAdded,
    removeFile,
  } = useServiceRequestForm(storage, formData, stepToShow, configuration);

  const header =
    configuration.businessName && state.isFirstQuestion() ? (
      <FormattedMessage
        id="serviceRequestForm.header.businessSelected"
        defaultMessage="Vamos a dejar un aviso a {businessName} para que se ponga en contacto contigo y te pase presupuesto"
        description="Mensaje que aparece encima de la primera pregunta cuando estamos pidiendo un presupuesto privado"
        values={{ businessName: configuration.businessName }}
      />
    ) : undefined;

  return (
    <ServiceRequestFormContext.Provider
      value={{
        formData: state.formData,
        handleStepCompleted,
        handleSubmit,
      }}
    >
      <FormRenderer
        state={state}
        goBackHandler={goBackHandler}
        goFirstHandler={goFirstHandler}
        onFileAdded={onFileAdded}
        removeFile={removeFile}
        uploads={uploads}
        businessId={configuration.businessId}
        campaign={configuration.campaign}
        campaignId={configuration.campaignId}
        content={configuration.content}
        userSource={configuration.userSource}
        hideSharePrivateServiceRequestStep={configuration.hideSharePrivateServiceRequestStep}
        showCommercialPhone={configuration.showCommercialPhone}
        enableHOExperiencePreference={state.isEnableHOExperiencePreference}
        extraConfiguration={state.extraConfiguration}
        header={header}
      />
    </ServiceRequestFormContext.Provider>
  );
}

export default ServiceRequestForm;
