import EmailDomainRepositoryInterface from '@src/core/domain/EmailDomain/EmailDomainRepositoryInterface';

class FetchAllEmailDomains {
  private repository: EmailDomainRepositoryInterface;

  constructor(emailDomainRepository: EmailDomainRepositoryInterface) {
    this.repository = emailDomainRepository;
  }

  execute(): string[] {
    return this.repository.fetchAll();
  }
}

export default FetchAllEmailDomains;
