import React from 'react';
import Link from '@src/ui/components/Link/Link';
import I18n from '@src/core/I18n';

function ThirdParty(props: { linkText: string; showDesignVariant?: boolean }): React.ReactElement {
  const LOCALE = I18n.intl().locale;

  switch (LOCALE) {
    case 'es-ES':
      return (
        <Link
          href="https://www.habitissimo.es/comunicacion_terceros"
          target="_blank"
          showDesignVariant={props.showDesignVariant}
        >
          {props.linkText}
        </Link>
      );
    case 'it-IT':
      return (
        <Link
          href="https://www.habitissimo.it/comunicazioni_terzi"
          target="_blank"
          showDesignVariant={props.showDesignVariant}
        >
          {props.linkText}
        </Link>
      );
    case 'pt-PT':
      return (
        <Link
          href="https://www.habitissimo.pt/comunicacoes_terceiros"
          target="_blank"
          showDesignVariant={props.showDesignVariant}
        >
          {props.linkText}
        </Link>
      );
    case 'es-MX':
      return (
        <Link
          href="https://www.habitissimo.com.mx/comunicacion_terceros"
          target="_blank"
          showDesignVariant={props.showDesignVariant}
        >
          {props.linkText}
        </Link>
      );
    case 'es-CL':
      return (
        <Link
          href="https://www.habitissimo.cl/comunicacion_terceros"
          target="_blank"
          showDesignVariant={props.showDesignVariant}
        >
          {props.linkText}
        </Link>
      );
    default:
      throw new Error('Country has no terms url');
  }
}

export default ThirdParty;
