import LocationRepositoryInterface from '@src/core/domain/Locations/LocationRepositoryInterface';

class GetLocationLegacyId {
  constructor(private readonly repository: LocationRepositoryInterface) {}

  execute(locationId: string): Promise<number> {
    return this.repository.getLegacyId(locationId);
  }
}

export default GetLocationLegacyId;
