class TooLargePhotoError extends Error {
  public fileName: string;

  constructor(fileName: string) {
    super('File too large');
    this.fileName = fileName;

    Object.setPrototypeOf(this, TooLargePhotoError.prototype);
  }
}

export default TooLargePhotoError;
