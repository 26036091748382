import React from 'react';
import { LocationStepRenderProps } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import styles from '@src/ui/apps/ServiceRequest/Steps/LocationStep/ChileanLocationStepRenderer.module.scss';
import LocationSelectInput from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationSelectInput';

function ChileanLocationStepRenderer({
  form,
  formData,
}: LocationStepRenderProps): React.ReactElement {
  return (
    <div className={styles.locationInputSelect}>
      <LocationSelectInput form={form} formData={formData} />
    </div>
  );
}

export default ChileanLocationStepRenderer;
