class LocationIdentifier {
  private constructor(
    readonly postalCode: string | undefined,
    readonly locationId: string | undefined
  ) {}

  static fromPostalCode(postalCode: string): LocationIdentifier {
    return new LocationIdentifier(postalCode, undefined);
  }

  static fromLocationId(locationId: string): LocationIdentifier {
    return new LocationIdentifier(undefined, locationId);
  }

  static fromPostalCodeOrLocationId(postalCode?: string, locationId?: string): LocationIdentifier {
    if (locationId) {
      return LocationIdentifier.fromLocationId(locationId);
    }

    return new LocationIdentifier(postalCode, undefined);
  }
}

export default LocationIdentifier;
