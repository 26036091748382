import React from 'react';
import Button from '@src/ui/components/Button/Button';
import { FormattedMessage } from 'react-intl';

export type NextButtonProps = {
  showDesignVariant?: boolean;
  disabled?: boolean;
};
function NextButton({ showDesignVariant, disabled }: NextButtonProps): React.ReactElement {
  return (
    <Button
      type="submit"
      data-testid="nextButton"
      showDesignVariant={showDesignVariant}
      disabled={disabled}
    >
      <FormattedMessage
        id="serviceRequestForm.navigationButtons.next"
        defaultMessage="Siguiente"
        description="Botón de navegación del formulario hacia adelante"
      />
    </Button>
  );
}

export default NextButton;
