import Question from '@src/core/domain/Questions/Question';
import { QuestionsRepositoryInterface } from '@src/core/domain/Questions/QuestionsRepositoryInterface';

class FetchServiceRequestQuestions {
  private questionsRepository: QuestionsRepositoryInterface;

  constructor(categoryRepository: QuestionsRepositoryInterface) {
    this.questionsRepository = categoryRepository;
  }

  execute(jobTypeId: string): Promise<Question[]> {
    return this.questionsRepository.fetchByJobTypeId(jobTypeId);
  }
}

export default FetchServiceRequestQuestions;
