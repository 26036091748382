import PhoneNumberValidator from '@src/core/domain/CustomerContactData/PhoneNumberValidator';
import InvalidPhoneNumberError from '@src/core/domain/CustomerContactData/InvalidPhoneNumberError';

class ItalianPhoneNumberValidator implements PhoneNumberValidator {
  validate(phoneNumber: string): void {
    const PHONE_LENGTH_MIN = 8;
    const PHONE_LENGTH_MAX = 11;
    const pattern = new RegExp(`^\\d{${PHONE_LENGTH_MIN},${PHONE_LENGTH_MAX}}$`);
    const phoneNumberWithoutBlanks = phoneNumber.replace(/\s+/g, '');

    const isValidPhoneNumber = pattern.test(phoneNumberWithoutBlanks);

    if (!isValidPhoneNumber) {
      throw new InvalidPhoneNumberError();
    }
    return;
  }
}

export default ItalianPhoneNumberValidator;
