import React from 'react';
import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import Link from '@src/ui/components/Link/Link';
import { FormattedMessage } from 'react-intl';

type ChangeJobTypeLinkProps = {
  onClick: () => void;
};

export function ChangeJobTypeLink({ onClick }: ChangeJobTypeLinkProps): React.ReactElement {
  return (
    <div className={styles.center}>
      <Link onClick={onClick} data-testid="goBackToJobTypeStep">
        <FormattedMessage
          id="serviceRequestForm.changeJobTypeLink"
          defaultMessage="Cambiar tipo de trabajo"
          description="Texto del link para volver al paso de selección del tipo de trabajo"
        />
      </Link>
    </div>
  );
}
