export default class HttpResponse<T> {
  private _statusCode: number;
  private _data: T;

  constructor(statusCode: number, data: T) {
    this._statusCode = statusCode;
    this._data = data;
  }

  public data(): T {
    return this._data;
  }

  public code(): number {
    return this._statusCode;
  }
}
