import { CookieStorage } from 'cookie-storage';
import { CookieOptions } from 'cookie-storage/lib/cookie-options';
import { BrowserStorage } from '@src/core/domain/storage/BrowserStorage';
import BrowserCalendar from '@src/core/infrastructure/time/BrowserCalendar';
import Calendar from '@src/core/domain/time/Calendar';

type ExtendedCookieOptions = CookieOptions & { maxAge?: number };

class CookieBrowserStorage implements BrowserStorage {
  private readonly _cookieStorage: CookieStorage;
  private readonly _maxAge?: number;

  constructor(options: ExtendedCookieOptions, readonly calendar: Calendar = new BrowserCalendar()) {
    this._cookieStorage = new CookieStorage(options);
    this._maxAge = options.maxAge;
  }

  public setItem(key: string, data: string): void {
    if (this._maxAge) {
      this._cookieStorage.setItem(key, data, {
        expires: this.addSecondsToDate(this.calendar.now(), this._maxAge),
      });
    } else {
      this._cookieStorage.setItem(key, data);
    }
  }

  public getItem(key: string): string | null {
    return this._cookieStorage.getItem(key);
  }

  public removeItem(key: string): void {
    return this._cookieStorage.removeItem(key);
  }

  private addSecondsToDate(initial: Date, seconds: number): Date {
    return new Date(initial.getTime() + seconds * 1000);
  }
}

export default CookieBrowserStorage;
