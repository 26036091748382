import Category from '@src/core/domain/Categories/Category';
import CategoryRepositoryInterface from '@src/core/domain/Categories/CategoryRepositoryInterface';

class SearchCategories {
  private repository: CategoryRepositoryInterface;

  constructor(repository: CategoryRepositoryInterface) {
    this.repository = repository;
  }

  execute(query: string): Promise<Category[]> {
    return this.repository.search(query);
  }
}

export default SearchCategories;
