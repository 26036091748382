import InputStatus from '@src/ui/helpers/form/InputStatus';
import { FieldError, FieldErrors, FieldName, Message } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';

export type UseFormHelperMethods<D> = {
  inputStatus: (inputName: keyof FieldErrors<D>) => InputStatus;
  setError: (name: FieldName<D>, message?: Message) => void;
  setValue: (name: FieldName<D>, value: string | undefined) => void;
  errorMessage: (inputName: keyof FieldErrors<D>) => string;
  getValues: (name?: FieldName<D>) => string | Record<string, unknown>;
  hasError: (inputName: keyof FieldErrors<D>) => boolean;
};

export function useFormHelper<D>(form: UseFormMethods<D>): UseFormHelperMethods<D> {
  function inputStatus(inputName: keyof FieldErrors<D>): InputStatus {
    const { touched } = form.formState;

    return hasError(inputName)
      ? InputStatus.ERROR
      : touched[inputName]
      ? InputStatus.SUCCESS
      : InputStatus.NEUTRAL;
  }

  const hasError = (inputName: keyof FieldErrors<D>): boolean => {
    return inputName in form.errors;
  };

  const setError = (name: FieldName<D>, message?: Message): void => {
    const inputRef = form.control.fieldsRef.current[name as string]?.ref as HTMLInputElement;
    if (inputRef && typeof inputRef.focus === 'function') {
      inputRef.focus();
    }

    form.setError(name, { message });
  };

  const setValue = (name: FieldName<D>, value?: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form.setValue(name, value);
  };

  const getValues = (inputName?: FieldName<D>): string | Record<string, unknown> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return form.getValues(inputName);
  };

  const errorMessage = (inputName: keyof FieldErrors<D>): string => {
    const filedError = form.errors[inputName] as FieldError;
    return filedError?.message as string;
  };

  return {
    inputStatus,
    setError,
    setValue,
    errorMessage,
    getValues,
    hasError,
  };
}
