import LocationRepositoryInterface from '@src/core/domain/Locations/LocationRepositoryInterface';
import HttpClient, { HttpResponse } from '../http';
import Locations from '@src/core/domain/Locations/Locations';

class LocationRepository implements LocationRepositoryInterface {
  constructor(readonly baseUrl: string, readonly httpClient: HttpClient) {}

  fetchMainLocations(): Promise<Locations> {
    return this.httpClient
      .get(this.endpoint('/locations'))
      .then((response: HttpResponse<unknown>) => Locations.fromJsonArray(response.data()));
  }

  fetchChildrenOf(locationId: string): Promise<Locations> {
    return this.httpClient
      .get(this.endpoint(`/locations/${locationId}`))
      .then((response: HttpResponse<unknown>) => Locations.fromJsonArray(response.data()));
  }

  searchLocationByText(searchText: string): Promise<Locations> {
    return this.httpClient
      .get(this.endpoint(`/locations/searchByText/${searchText}`))
      .then((response: HttpResponse<unknown>) => Locations.fromJsonArray(response.data()));
  }

  getLegacyId(locationId: string): Promise<number> {
    return this.httpClient
      .get(this.endpoint(`/tracking/legacyId/locations/${locationId}`))
      .then((response: HttpResponse<unknown>) => this.fromJsonData(response.data()));
  }

  private endpoint(location: string): string {
    return this.baseUrl + location;
  }

  private fromJsonData(jsonData: unknown) {
    type LegacyIdData = {
      legacyId: number;
    };

    const data: LegacyIdData = jsonData as LegacyIdData;

    return data.legacyId;
  }
}

export default LocationRepository;
