import Factory from '@src/core/Factory';
import SearchCategories from '@src/core/useCases/Category/SearchCategories';

class SearchCategoriesFactory {
  private static _instance: SearchCategories | undefined;

  static create(): SearchCategories {
    if (this._instance === undefined) {
      this._instance = new SearchCategories(Factory.newCategoryRepository());
    }

    return this._instance;
  }

  static setInstance(instance: SearchCategories): void {
    this._instance = instance;
  }
}

export default SearchCategoriesFactory;
