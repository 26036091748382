import PhotoRepositoryInterface from '@src/core/domain/Photos/PhotoRepositoryInterface';
import UploadedPhoto from '@src/core/domain/Photos/UploadedPhoto';
import Photo from '@src/core/domain/Photos/Photo';
import UploadPhotoError from '@src/core/domain/Photos/UploadPhotoError';
import HttpClient from '@src/core/infrastructure/http/HttpClient';
import { PhotoUploadType } from './PhotoUploadType';

class PhotoRepository implements PhotoRepositoryInterface {
  constructor(readonly baseUrl: string, readonly httpClient: HttpClient) {}

  async upload(photo: Photo): Promise<UploadedPhoto> {
    const file = photo.file;
    try {
      const response = await this.httpClient.post<PhotoUploadType>(
        `${this.baseUrl}/serviceRequest/photo`,
        file,
        {
          headers: {
            'Content-Type': file.type,
          },
        }
      );
      const data = response.data();
      return new UploadedPhoto(data.id, data.thumbnailUrl, file);
    } catch (error) {
      throw new UploadPhotoError(file.name);
    }
  }
}

export default PhotoRepository;
