import DivisionRepositoryInterface from '@src/core/domain/Divisions/DivisionRepositoryInterface';
import Division from '@src/core/domain/Divisions/Division';

class SearchDivisions {
  constructor(private readonly repository: DivisionRepositoryInterface) {}

  execute(searchId: string): Promise<Division> {
    return this.repository.fetchBySubSubCategory(searchId);
  }
}

export default SearchDivisions;
