import CategoryRepositoryInterface from '@src/core/domain/Categories/CategoryRepositoryInterface';
import CategoryRepository from '@src/core/infrastructure/Category/CategoryRepository';
import HttpClient from '@src/core/infrastructure/http';
import LocationRepository from '@src/core/infrastructure/Location/LocationRepository';
import QuestionsRepository from '@src/core/infrastructure/Questions/QuestionsRepository';
import ApiCategoryReader from '@src/core/infrastructure/Category/ApiCategoryReader';
import ApiQuestionsReader from '@src/core/infrastructure/Questions/ApiQuestionsReader';
import QuestionsRepositoryInterface from '@src/core/domain/Questions/QuestionsRepositoryInterface';
import PhotoRepositoryInterface from '@src/core/domain/Photos/PhotoRepositoryInterface';
import PhotoRepository from '@src/core/infrastructure/Photos/PhotoRepository';
import EmailDomainRepositoryInterface from '@src/core/domain/EmailDomain/EmailDomainRepositoryInterface';
import EmailDomainRepository from '@src/core/infrastructure/EmailDomain/EmailDomainRepository';
import HOExperiencePreferenceRepositoryInterface from '@src/core/domain/HOExperiencePreference/HOExperiencePreferenceRepositoryInterface';
import HOExperiencePreferenceRepository from '@src/core/infrastructure/HOExperiencePreference/HOExperiencePreferenceRepository';
import DivisionRepository from '@src/core/infrastructure/Division/DivisionRepository';
import ApiDivisionReader from '@src/core/infrastructure/Division/ApiDivisionReader';
import StateRepository from '@src/core/infrastructure/State/StateRepository';
import ApiStateReader from '@src/core/infrastructure/State/ApiStateReader';
import HiddenCategoriesRepositoryInterface from '@src/core/domain/Categories/HiddenCategoriesRepositoryInterface';
import HiddenCategoriesRepository from '@src/core/infrastructure/Category/HiddenCategoriesRepository';

class Factory {
  static newApiQuestionsReader(): ApiQuestionsReader {
    return new ApiQuestionsReader(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }

  static newQuestionsRepository(): QuestionsRepositoryInterface {
    return new QuestionsRepository(Factory.newApiQuestionsReader());
  }

  static newCategoryRepository(): CategoryRepositoryInterface {
    return new CategoryRepository(Factory.newApiCategoryReader());
  }

  static newHiddenCategories(): HiddenCategoriesRepositoryInterface {
    return new HiddenCategoriesRepository();
  }

  static newApiCategoryReader(): ApiCategoryReader {
    return new ApiCategoryReader(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }

  static newLocationRepository(): LocationRepository {
    return new LocationRepository(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }

  static newPhotoRepository(): PhotoRepositoryInterface {
    return new PhotoRepository(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }

  static newHttpClient(): HttpClient {
    return new HttpClient();
  }

  static serviceRequestBaseUrl(): string {
    return (window.reactEnv?.SERVICE_REQUEST_BASE_URL ??
      process.env.SERVICE_REQUEST_BASE_URL) as string;
  }

  static newEmailDomainRepository(): EmailDomainRepositoryInterface {
    return new EmailDomainRepository();
  }

  static newHOExperiencePreferenceRepository(): HOExperiencePreferenceRepositoryInterface {
    return new HOExperiencePreferenceRepository();
  }

  static newDivisionRepository(): DivisionRepository {
    return new DivisionRepository(Factory.newApiDivisionReader());
  }

  static newApiDivisionReader(): ApiDivisionReader {
    return new ApiDivisionReader(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }

  static newStateRepository(): StateRepository {
    return new StateRepository(Factory.newApiStateReader());
  }

  static newApiStateReader(): ApiStateReader {
    return new ApiStateReader(Factory.serviceRequestBaseUrl(), Factory.newHttpClient());
  }
}

export default Factory;
