import EmailDomainRepositoryInterface from '@src/core/domain/EmailDomain/EmailDomainRepositoryInterface';
import I18n from '@src/core/I18n';

class EmailDomainRepository implements EmailDomainRepositoryInterface {
  LOCALE = I18n.intl().locale;
  fetchAll(): string[] {
    return require(`@src/data/emailDomains/${this.LOCALE}.json`);
  }
}

export default EmailDomainRepository;
