import I18n from '@src/core/I18n';

function mostPopularSubsToSearch(): string[] {
  const LOCALE = I18n.intl().locale;
  switch (LOCALE) {
    case 'es-ES':
      return ['Reformas viviendas', 'Pintores', 'Toldos', 'Aire acondicionado'];
    case 'it-IT':
      return ['Pulizie', 'Imbianchini', 'Aire condizionata', 'Tende da sole'];
    case 'pt-PT':
      return ['Arquitetos', 'Remodelação de casa', 'Pintura', 'Limpeza'];
    default:
      return [];
  }
}

export default mostPopularSubsToSearch;
