import React, { FunctionComponent } from 'react';
import Category from '@src/core/domain/Categories/Category';
import styles from './MostPopularSubcategories.module.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

type MostPopularProps = {
  popularSubcategories: Category[];
  onSubcategoryClick: (category: Category) => void;
  showDesignVariant?: boolean;
};

const MostPopularSubcategories: FunctionComponent<MostPopularProps> = (props: MostPopularProps) => {
  const intl = useIntl();

  return (
    <div
      id={'mostPopularSubcategoriesContainer'}
      className={classNames(styles.mostPopularSubcategoriesHidden, {
        [styles['mostPopularSubcategories--showDesignVariant']]: props.showDesignVariant,
      })}
      data-vwo="mostPopularSubcategoriesContainer"
    >
      <div className={styles.mostPopularTitle}>
        {intl.formatMessage({
          id: 'serviceRequestForm.jobTypeStep.mostPopularSubcategoriesTitle',
          defaultMessage: 'Más populares: ',
          description:
            'Título para la sección de subcategorías más populares a mostrar como sugerencia en el formulario',
        })}
        {props.popularSubcategories.map((category, index) => (
          <span key={category.id}>
            <button
              type={'button'}
              className={styles.mostPopularSubcategories}
              onClick={() => props.onSubcategoryClick(category)}
            >
              {category.name}
            </button>
            {index < props.popularSubcategories.length - 1 && ', '}
          </span>
        ))}
      </div>
    </div>
  );
};

export default MostPopularSubcategories;
