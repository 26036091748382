import Factory from '@src/core/Factory';
import FetchAllJobTypesHOExperiencePreference from '@src/core/useCases/HOExperiencePreference/FetchAllJobTypesHOExperiencePreference';

class FetchAllJobTypesHOExperiencePreferenceFactory {
  private static _instance: FetchAllJobTypesHOExperiencePreference | undefined;

  static create(): FetchAllJobTypesHOExperiencePreference {
    if (this._instance === undefined) {
      this._instance = new FetchAllJobTypesHOExperiencePreference(
        Factory.newHOExperiencePreferenceRepository()
      );
    }

    return this._instance;
  }

  static setInstance(instance: FetchAllJobTypesHOExperiencePreference): void {
    this._instance = instance;
  }
}

export default FetchAllJobTypesHOExperiencePreferenceFactory;
