import { FormData } from '@src/ui/apps/ServiceRequest/useServiceRequestForm';

import Category from '@src/core/domain/Categories/Category';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import { ExtraConfiguration, FormConfiguration } from '@src/core/ApplicationConfiguration';
import { FormErrors } from '@src/ui/apps/ServiceRequest/errors/mapError';
import Question from '@src/core/domain/Questions/Question';
import { BrowserStorage } from '@src/core/domain/storage/BrowserStorage';
import { ServiceRequestFormRendererProps } from './ServiceRequestFormRenderer';
import { CreateServiceRequestSuccess } from '@src/core/useCases/ServiceRequest/CreateServiceRequest';

export type ServiceRequestFormState = {
  errors: FormErrors;
  questions: Question[];
  needsToLoadQuestions: boolean;
  currentStep: number;
  totalSteps: number;
  isLoadingSlug: boolean;
  isSendingRequest: boolean;
  formData: FormData;
  categories: Category[];
  isFirstQuestion: () => boolean;
  isEnableHOExperiencePreference: boolean;
  extraConfiguration: ExtraConfiguration | undefined;
};

export enum Action {
  FORM_ERROR = 'FORM_ERROR',
  CATEGORIES_LOADED = 'CATEGORIES_LOADED',
  QUESTIONS_LOADED = 'QUESTIONS_LOADED',
  INITIALIZATION_FROM_SLUG_FAILED = 'INITIALIZATION_FROM_SLUG_FAILED',
  SENDING_FORMDATA = 'SENDING_FORMDATA',
  SUBMIT_STEP = 'SUBMIT_STEP',
  GO_BACK_ONE_STEP = 'GO_BACK_ONE_STEP',
  SERVICE_REQUEST_COMPLETED = 'SERVICE_REQUEST_COMPLETED',
  SET_JOB_TYPE_FROM_SLUG = 'SET_JOB_TYPE_FROM_SLUG',
  SET_CATEGORY_FROM_SLUG = 'SET_CATEGORY_FROM_SLUG',
  GO_FIRST_STEP = 'GO_FIRST_STEP',
  CHANGE_HO_EXPERIENCE_PREFERENCE = 'CHANGE_HO_EXPERIENCE_PREFERENCE',
}

type ServiceRequestFormActions =
  | { type: Action.FORM_ERROR; errors: FormErrors }
  | { type: Action.CATEGORIES_LOADED; categories: CategoryGroupTree }
  | { type: Action.QUESTIONS_LOADED; questions: Question[] }
  | { type: Action.INITIALIZATION_FROM_SLUG_FAILED; isLoading: boolean }
  | { type: Action.SENDING_FORMDATA; formData: FormData }
  | { type: Action.SUBMIT_STEP; formData: FormData }
  | { type: Action.GO_BACK_ONE_STEP }
  | { type: Action.SERVICE_REQUEST_COMPLETED; initialState: ServiceRequestFormState }
  | { type: Action.SET_CATEGORY_FROM_SLUG; categoryName: string }
  | { type: Action.SET_JOB_TYPE_FROM_SLUG; jobTypeId: string; categoryName: string }
  | { type: Action.GO_FIRST_STEP }
  | { type: Action.CHANGE_HO_EXPERIENCE_PREFERENCE; displayStep: boolean };

export const serviceRequestFormStateReducer = (
  prevState: ServiceRequestFormState,
  action: ServiceRequestFormActions
): ServiceRequestFormState => {
  switch (action.type) {
    case Action.FORM_ERROR:
      return {
        ...prevState,
        errors: action.errors,
        isSendingRequest: false,
      };

    case Action.QUESTIONS_LOADED:
      return {
        ...prevState,
        questions: action.questions,
        needsToLoadQuestions: false,
        totalSteps: 5 + action.questions.length,
      };

    case Action.CATEGORIES_LOADED:
      return {
        ...prevState,
        categories: action.categories.toArray(),
      };

    case Action.INITIALIZATION_FROM_SLUG_FAILED:
      return {
        ...prevState,
        isLoadingSlug: action.isLoading,
      };

    case Action.SENDING_FORMDATA:
      return {
        ...prevState,
        formData: action.formData,
        isSendingRequest: true,
      };

    case Action.SUBMIT_STEP:
      const newState = {
        ...prevState,
        formData: action.formData,
        currentStep: prevState.currentStep + 1,
      };
      if (prevState.formData.jobTypeId !== action.formData.jobTypeId) {
        if (!prevState.extraConfiguration?.quickFlow) {
          newState.needsToLoadQuestions = true;
        }
        newState.formData.answers = {};
        newState.questions = [];
      }
      return newState;

    case Action.GO_BACK_ONE_STEP:
      return {
        ...prevState,
        currentStep: prevState.currentStep - 1,
      };

    case Action.GO_FIRST_STEP:
      return {
        ...prevState,
        extraConfiguration: {
          ...prevState.extraConfiguration,
          subSubCategories: [],
        },
        currentStep: 1,
      };

    case Action.SERVICE_REQUEST_COMPLETED:
      return action.initialState;

    case Action.SET_JOB_TYPE_FROM_SLUG:
      return {
        ...prevState,
        currentStep: prevState.extraConfiguration?.skipCategoryStep ? 2 : 1,
        isLoadingSlug: false,
        needsToLoadQuestions: !prevState.extraConfiguration?.quickFlow,
        questions: [],
        totalSteps: prevState.totalSteps - prevState.questions.length,
        formData: {
          ...prevState.formData,
          answers: {},
          jobTypeId: action.jobTypeId,
          categoryName: action.categoryName,
        },
      };

    case Action.SET_CATEGORY_FROM_SLUG:
      return {
        ...prevState,
        currentStep: 1,
        isLoadingSlug: false,
        categories: prevState.categories,
        formData: {
          ...prevState.formData,
          answers: {},
          categoryName: action.categoryName,
        },
      };

    case Action.CHANGE_HO_EXPERIENCE_PREFERENCE:
      return {
        ...prevState,
        isEnableHOExperiencePreference: action.displayStep,
      };

    default:
      return prevState;
  }
};

export interface ServiceRequestFormProps {
  configuration: FormConfiguration;
  storage: BrowserStorage;
  stepToShow?: number;
  formData?: Partial<FormData>;
  onSuccessCallback?: (response: CreateServiceRequestSuccess) => void;
  FormRenderer?: React.ComponentType<ServiceRequestFormRendererProps>;
}
