import { EventBus } from '@src/core/infrastructure/Events/EventBus';
import {
  CategorySelected,
  ContactDataStepRendered,
  DescriptionStepRendered,
  JobTypeSelected,
  JobTypeStepRendered,
  LocationStepRendered,
  LocationWithParentSubmitted,
  PostalCodeLocationSubmitted,
  QuestionStepRendered,
  ServiceRequestFormSubmitted,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import GetLocationLegacyIdFactory from '@src/core/useCases/Locations/GetLocationLegacyIdFactory';
import GetCategoryLegacyIdFactory from '@src/core/useCases/Category/GetCategoryLegacyIdFactory';
import AccumulatedData from '@src/core/infrastructure/Events/DataLayer/AccumulatedData';
import SearchDivisionsFactory from '@src/core/useCases/Divisions/SearchDivisionsFactory';
import SearchStateFactory from '@src/core/useCases/State/SearchStateFactory';

class DataLayerServiceRequestFormEventListener {
  private _window: Window;
  private _accumulatedData: AccumulatedData;
  private _country: string;

  constructor(window: Window, country: string) {
    this._window = window;
    this._country = country;
    this._window['VWO'] = window['VWO'] || [];
    this._window['dataLayer'] = window['dataLayer'] || [];
    this._accumulatedData = {
      subcategory_id: '',
      division_sub_id: '',
      subsubcategory_id: '',
      state_id: '',
      action: '',
    };
  }

  register(dispatcher: EventBus): void {
    dispatcher.addListener(JobTypeStepRendered.eventName, this.categoryStepViewed.bind(this));
    dispatcher.addListener(CategorySelected.eventName, this.categorySelected.bind(this));
    dispatcher.addListener(JobTypeSelected.eventName, this.jobTypeSelected.bind(this));
    dispatcher.addListener(LocationStepRendered.eventName, this.locationStepViewed.bind(this));
    dispatcher.addListener(
      PostalCodeLocationSubmitted.eventName,
      this.postalCodeLocationSubmitted.bind(this)
    );

    dispatcher.addListener(
      LocationWithParentSubmitted.eventName,
      this.locationWithParentSubmitted.bind(this)
    );

    dispatcher.addListener(QuestionStepRendered.eventName, this.questionsStepRendered.bind(this));

    dispatcher.addListener(
      DescriptionStepRendered.eventName,
      this.descriptionStepViewed.bind(this)
    );
    dispatcher.addListener(
      ContactDataStepRendered.eventName,
      this.contactDataStepViewed.bind(this)
    );
    dispatcher.addListener(
      ServiceRequestFormSubmitted.eventName,
      this.serviceRequestSubmitted.bind(this)
    );
  }

  private categoryStepViewed(): void {
    this.logVWOEventName('vwoView_step_subcategory');
  }

  private categorySelected(event: CategorySelected): void {
    this.getLegacyIdOfCategory(event.category.id).then((legacyId: string) => {
      this._accumulatedData = {
        subcategory_id: legacyId,
        division_sub_id: '',
        subsubcategory_id: '',
        state_id: '',
        action: 'category selected',
      };

      this.logEventWithAcumulatedData(this._accumulatedData);
    });
  }

  private jobTypeSelected(event: JobTypeSelected): void {
    this._accumulatedData.action = 'subsubcategory selected';
    this._accumulatedData.state_id = '';

    this.getLegacyIdOfCategory(event.jobType.id).then((legacyId: string) => {
      this._accumulatedData.subsubcategory_id = legacyId;
      if (this._country !== 'cl' && this._country !== 'mx') {
        this.divisionsWithLocationSubmitted(event.jobType.id).then(() => {});
      } else {
        this.logEventWithAcumulatedData(this._accumulatedData);
      }
    });
  }

  private locationStepViewed(event: LocationStepRendered): void {
    if (
      this._accumulatedData.subsubcategory_id === '' &&
      this._accumulatedData.action !== 'subsubcategory selected' &&
      event.jobTypeId != null
    ) {
      this.getLegacyIdOfCategory(event.jobTypeId).then((legacyId: string) => {
        this._accumulatedData.subsubcategory_id = legacyId;
        this._accumulatedData.action = 'subsubcategory selected';
        if (this._country !== 'cl' && this._country !== 'mx') {
          this.divisionsWithLocationSubmitted(event.jobTypeId as string).then(() => {});
        } else {
          this.logEventWithAcumulatedData(this._accumulatedData);
        }
      });
    }
  }

  private postalCodeLocationSubmitted(event: PostalCodeLocationSubmitted): void {
    if (this._country === 'es') {
      this.stateWithPostalCodeSubmitted(event.postalCode).then(() => {});
    } else {
      this._accumulatedData.state_id = event.postalCode;
      this._accumulatedData.action = 'location selected';

      this.logEventWithAcumulatedData(this._accumulatedData);
    }
  }

  private locationWithParentSubmitted(event: LocationWithParentSubmitted): void {
    this.getLegacyIdOfLocation(event.firstLevelLocation.id).then((legacyId: string) => {
      this._accumulatedData.state_id = legacyId;
      this._accumulatedData.action = 'location selected';

      this.logEventWithAcumulatedData(this._accumulatedData);
    });
  }

  private questionsStepRendered(): void {
    this._accumulatedData.action = 'view dynamic questions';

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private descriptionStepViewed(): void {
    this._accumulatedData.action = 'view step description';

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private contactDataStepViewed(): void {
    this.logEventName('optimize.view_step_contact_details_customer');

    this.logVWOEventName('vwoView_step_contact_details');

    this._accumulatedData.action = 'view step contact details customer';

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private serviceRequestSubmitted(): void {
    this._accumulatedData.action = 'service request submitted';

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private async getLegacyIdOfCategory(categoryId: string): Promise<string> {
    if (categoryId === '') return '';
    const legacyId = await GetCategoryLegacyIdFactory.create().execute(categoryId);

    return legacyId.toString();
  }

  private async getLegacyIdOfLocation(locationId: string): Promise<string> {
    const legacyId = await GetLocationLegacyIdFactory.create().execute(locationId);

    return legacyId.toString();
  }

  private async divisionsWithLocationSubmitted(categoryId: string): Promise<void> {
    try {
      const divisionSearch = await SearchDivisionsFactory.create().execute(categoryId);

      this._accumulatedData.subcategory_id = divisionSearch.subcategoryId.toString();
      this._accumulatedData.division_sub_id = divisionSearch.divisionId.toString();
    } catch (e) {}

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private async stateWithPostalCodeSubmitted(postalCode: string): Promise<void> {
    try {
      const stateSearch = await SearchStateFactory.create().execute(postalCode);
      this._accumulatedData.state_id = stateSearch.id.toString();
    } catch (e) {
      this._accumulatedData.state_id = postalCode;
    }
    this._accumulatedData.action = 'location selected';

    this.logEventWithAcumulatedData(this._accumulatedData);
  }

  private logEventWithAcumulatedData(data: AccumulatedData): void {
    this._window['dataLayer'].push({
      ...this._accumulatedData,
      ...data,
      event: 'eventga',
    });
  }

  private logEventName(name: string): void {
    this._window['dataLayer'].push({
      event: name,
    });
  }

  private logVWOEventName(name: string): void {
    this._window['VWO'].event?.(name);
  }
}

export default DataLayerServiceRequestFormEventListener;
