import EmailAddress from './EmailAddress';
import PhoneNumber from './PhoneNumber';
import { getLocalizedService } from '@src/ui/helpers/localization';
import PhoneNumberValidator from './PhoneNumberValidator';

class CustomerContactData {
  readonly _email: EmailAddress;
  readonly _phone: PhoneNumber;

  constructor(readonly name: string, email: string, phone: string) {
    this._email = new EmailAddress(email);
    this._phone = new PhoneNumber(
      phone,
      getLocalizedService<PhoneNumberValidator>('PhoneNumberValidator')
    );
  }

  get email(): string {
    return this._email.toString();
  }

  get phone(): string {
    return this._phone.toString();
  }
}

export default CustomerContactData;
