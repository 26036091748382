import { FormattedMessage } from 'react-intl';
import React from 'react';
import Alert, { AlertSize, AlertTypes } from '@src/ui/components/Alert/Alert';
import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import PhoneRingingBlue from './phone-ringing-blue.svg';
import Image from '@src/ui/components/Image/Image';

export default function CommercialPhone(): React.ReactElement {
  return (
    <div className={styles['serviceRequestForm__commercial-phone']}>
      <Alert
        variant={AlertTypes.BANNER}
        size={AlertSize.MEDIUM}
        icon={<Image className={styles['']} src={PhoneRingingBlue} alt={'Commercial phone'} />}
      >
        <span data-testid="commercialPhone">
          <div className={styles['serviceRequestForm__commercial-phone-text']}>
            <FormattedMessage
              id="serviceRequestForm.commercialPhone"
              // eslint-disable-next-line formatjs/enforce-placeholders
              defaultMessage="¿Necesitas ayuda con tu solicitud? <breakLine></breakLine> Llámanos gratis al <commercialPhone> 900 373 954 </commercialPhone>"
              description="Telefono comercial que aparece si esta habilitado en la parte inferior del formulario durante todos los pasos"
              values={{
                breakLine: function callMePhone(text: string) {
                  return <br></br>;
                },
                commercialPhone: function callMePhone(text: string) {
                  const phone = ('tel:' + text.toString()).replace(/\s+/g, '');
                  return <a href={phone}>{text}</a>;
                },
              }}
            />
          </div>
          <div className={styles['serviceRequestForm__commercial-phone-text-device']}>
            <FormattedMessage
              id="serviceRequestForm.commercialPhoneDevice"
              // eslint-disable-next-line formatjs/enforce-placeholders
              defaultMessage="¿Necesitas ayuda con tu solicitud? Llámanos gratis al <commercialPhone> 900 373 954 </commercialPhone>"
              description="Telefono comercial que aparece si esta habilitado en la parte inferior del formulario durante todos los pasos"
              values={{
                commercialPhone: function callMePhone(text: string) {
                  const phone = ('tel:' + text.toString()).replace(/\s+/g, '');
                  return <a href={phone}>{text}</a>;
                },
              }}
            />
          </div>
        </span>
      </Alert>
    </div>
  );
}
