import React, { PropsWithChildren } from 'react';
import styles from './StepTitle.module.scss';

type StepTitleProps = {
  className?: string;
  'data-testid'?: string;
  'data-vwo'?: string;
};

function StepTitle(props: PropsWithChildren<StepTitleProps>): React.ReactElement {
  return (
    <p
      className={`${styles.StepTitle} ${props.className}`}
      data-testid={props['data-testid']}
      data-vwo={props['data-vwo']}
    >
      {props.children}
    </p>
  );
}

export default StepTitle;
