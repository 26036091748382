import React, { ChangeEvent, FocusEvent } from 'react';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import Input from '@src/ui/components/Input/Input';
import Image from '@src/ui/components/Image/Image';
import FormField, { FormFieldProps } from '@src/ui/components/FormField/FormField';
import { ReferenceableComponent } from '@src/ui/helpers/masks/withMask';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import styles from '../Input/Input.module.scss';

type InputProxyProps = ReferenceableComponent & {
  inputName: string;
  inputValue?: string;
  inputDefaultValue?: string;
  inputType?: string;
  inputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputOnBlur?: (focusEvent: FocusEvent<HTMLInputElement>) => void;
  inputOnKeyPress?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  inputOnKeyUp?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  inputOnClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  inputStatus?: InputStatus;
  readOnly?: boolean;
  showDesignVariant?: boolean;
  inputPlaceholder?: string;
  icon?: string;
};

export type LabelledInputProps = InputProxyProps & FormFieldProps;
function LabelledInput(props: LabelledInputProps): React.ReactElement {
  const inputProps = {
    status: props.inputStatus,
    inputRef: props.inputRef,
    type: props.inputType || 'text',
    name: props.inputName,
    id: props.inputId,
    value: props.inputValue,
    defaultValue: props.inputDefaultValue,
    onChange: props.inputOnChange,
    onBlur: props.inputOnBlur,
    onKeyPress: props.inputOnKeyPress,
    onKeyUp: props.inputOnKeyUp,
    onClick: props.inputOnClick,
    'data-testid': props['data-testid'],
    'data-vwo': props['data-vwo'],
    readOnly: props.readOnly,
    showDesignVariant: props.showDesignVariant,
    placeholder: props.inputPlaceholder,
  };

  return (
    <FormField
      inputId={props.inputId}
      labelText={props.labelText}
      inputExample={props.inputExample}
      errorMessage={props.errorMessage}
      warningMessage={props.warningMessage}
      data-vwo-label={props['data-vwo-label']}
      showDesignVariant={props.showDesignVariant}
    >
      {props.showDesignVariant && props.icon ? (
        <div className={styles.inputPostalCodeContainer__showDesignVariant__placeholder}>
          <Image src={props.icon} className={styles.postalCodeMapIcon} alt={'icon'} />
          <Input {...inputProps} />
        </div>
      ) : (
        <Input {...inputProps} />
      )}

      {props.showDesignVariant && props.errorMessage && (
        <ValidationError showDesignVariant={props.showDesignVariant}>
          {props.errorMessage}
        </ValidationError>
      )}
    </FormField>
  );
}

export default LabelledInput;
