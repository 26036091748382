import React, { useEffect } from 'react';
import styles from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestRenderer.module.scss';
import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import ButtonGroup from '@src/ui/apps/ServiceRequest/ButtonGroup';
import Image from '@src/ui/components/Image/Image';
import forwardIcon from './Presupuesto_aprobado.svg';
import { FormattedMessage } from 'react-intl';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  ForwardServiceRequestClickedViewDetails,
  ForwardServiceRequestRendered,
} from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestEvents';
import Alert, { AlertSize, AlertTypes } from '@src/ui/components/Alert/Alert';
import { ForwardServiceRequestStatus } from '@src/ui/apps/ForwardServiceRequest/withForwardServiceRequest';

type ForwardServiceRequestRendererProps = {
  businessName: string;
  jobTypeName: string;
  serviceRequestUrl: string;
  onForwardReject: () => void;
  onForwardAccept: () => void;
  errorMessage: string;
  status: ForwardServiceRequestStatus;
};

function ForwardServiceRequestRenderer({
  businessName,
  jobTypeName,
  serviceRequestUrl,
  onForwardReject,
  onForwardAccept,
  errorMessage,
  status,
}: ForwardServiceRequestRendererProps): React.ReactElement {
  useEffect(() => {
    eventBusSingleton.fireEvent(new ForwardServiceRequestRendered());
  }, []);

  return (
    <div className={styles.forwardServiceRequest}>
      <Image src={forwardIcon} />
      <p className={styles.forwardServiceRequest__header}>
        <FormattedMessage
          id="forwardServiceRequest.header"
          defaultMessage="Ya tienes una solicitud activa de {jobTypeName}"
          description="Enlace a la ficha de un presupuesto en el paso de invitar profesionales"
          values={{ jobTypeName: jobTypeName }}
        />
      </p>
      <a
        className={styles.forwardServiceRequest__link}
        href={serviceRequestUrl}
        onClick={(ev) => {
          ev.preventDefault();
          eventBusSingleton.fireEvent(new ForwardServiceRequestClickedViewDetails());
          window.location.assign(serviceRequestUrl);
        }}
      >
        <FormattedMessage
          id="forwardServiceRequest.link"
          defaultMessage="Ver solicitud"
          description="Enlace a la ficha de un presupuesto en el paso de invitar profesionales"
        />
      </a>
      <p className={styles.forwardServiceRequest__cta}>
        <FormattedMessage
          id="forwardServiceRequest.cta"
          defaultMessage="¿Quieres que avisemos también a {businessName}?"
          description="Pregunta de confirmación para compartir una petición de presupuesto con un profesional"
          values={{ businessName: businessName }}
        />
      </p>

      {status === ForwardServiceRequestStatus.FORWARD_SUCCEED && (
        <Alert variant={AlertTypes.SUCCESS} size={AlertSize.SMALL}>
          <FormattedMessage
            id="forwardServiceRequest.successMessage"
            defaultMessage="Ya hemos avisado a {businessName}. Te redirigimos al presupuesto."
            description="Mensaje que mostramos cuando se ha reenviado correctamente un presupuesto a un profesional"
            values={{ businessName: businessName }}
          />
        </Alert>
      )}
      {status === ForwardServiceRequestStatus.ERROR && (
        <Alert variant={AlertTypes.ERROR} size={AlertSize.SMALL}>
          {errorMessage}
        </Alert>
      )}

      <ButtonGroup className={styles.forwardServiceRequest__buttons}>
        {status !== ForwardServiceRequestStatus.FORWARD_SUCCEED && (
          <Button
            onClick={onForwardAccept}
            className={styles.forwardServiceRequest__button}
            disabled={status === ForwardServiceRequestStatus.ERROR}
            isLoading={status === ForwardServiceRequestStatus.FORWARD_REQUESTED}
            data-testid="forwardServiceRequestAcceptButton"
          >
            <FormattedMessage
              id="forwardServiceRequest.buttons.accept"
              defaultMessage="Sí, quiero su presupuesto"
              description="Botón para confirmar la invitación de un profesional a un presupuesto creado anteriormente"
            />
          </Button>
        )}

        {status !== ForwardServiceRequestStatus.FORWARD_SUCCEED && (
          <Button
            variant={ButtonTypes.SECONDARY}
            onClick={onForwardReject}
            className={styles.forwardServiceRequest__button}
          >
            {status !== ForwardServiceRequestStatus.ERROR && (
              <FormattedMessage
                id="forwardServiceRequest.buttons.reject"
                defaultMessage="Quiero otro tipo de trabajo"
                description="Botón de invitación a un profesional a un nuevo presupuesto"
              />
            )}
            {status === ForwardServiceRequestStatus.ERROR && (
              <FormattedMessage
                id="forwardServiceRequest.buttons.createNewServiceRequest"
                defaultMessage="Pide un nuevo presupuesto"
                description="Botón para crear un nuevo presupuesto"
              />
            )}
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}

export default ForwardServiceRequestRenderer;
