import { EventInterface } from '@src/core/domain/Events/EventInterface';

class ForwardServiceRequestRendered implements EventInterface {
  eventName = 'ForwardServiceRequestRendered';

  static get eventName(): string {
    return 'ForwardServiceRequestRendered';
  }
}

class ForwardServiceRequestSuccess implements EventInterface {
  eventName = 'ForwardServiceRequestSuccess';

  static get eventName(): string {
    return 'ForwardServiceRequestSuccess';
  }

  constructor(readonly businessName: string, readonly serviceRequestUrl: string) {}
}

class ForwardServiceRequestError implements EventInterface {
  eventName = 'ForwardServiceRequestError';

  static get eventName(): string {
    return 'ForwardServiceRequestError';
  }

  constructor(readonly errorMessage: string) {}
}

class ForwardServiceRequestClickedReject implements EventInterface {
  eventName = 'ForwardServiceRequestClickedReject';

  static get eventName(): string {
    return 'ForwardServiceRequestClickedReject';
  }
}

class ForwardServiceRequestClickedAccept implements EventInterface {
  eventName = 'ForwardServiceRequestClickedAccept';

  static get eventName(): string {
    return 'ForwardServiceRequestClickedAccept';
  }
}

class ForwardServiceRequestClickedViewDetails implements EventInterface {
  eventName = 'ForwardServiceRequestClickedViewDetails';

  static get eventName(): string {
    return 'ForwardServiceRequestClickedViewDetails';
  }
}

export {
  ForwardServiceRequestRendered,
  ForwardServiceRequestSuccess,
  ForwardServiceRequestError,
  ForwardServiceRequestClickedReject,
  ForwardServiceRequestClickedAccept,
  ForwardServiceRequestClickedViewDetails,
};
