import { EventInterface } from '@src/core/domain/Events/EventInterface';

type EventProcessor<T> = (event: T) => void;

class EventBus {
  private _listeners: Partial<Record<string, EventProcessor<unknown>[]>>;

  constructor() {
    this._listeners = {};
  }

  addListener<T>(eventName: string, callback: EventProcessor<T>): void {
    if (!(eventName in this._listeners)) {
      this._listeners[eventName] = [];
    }
    (this._listeners[eventName] as EventProcessor<T>[]).push(callback);
  }

  fireEvent(event: EventInterface): void {
    const listeners = this._listeners[event.eventName] || [];

    listeners.forEach((callback) => callback(event));
  }
}

const eventBusSingleton = new EventBus();

export { EventBus, eventBusSingleton };
export type { EventProcessor };
