import StateReader from '@src/core/infrastructure/State/StateReader';
import HttpClient from '@src/core/infrastructure/http';

class ApiStateReader implements StateReader {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async fetchByPostalCode(query: string): Promise<string> {
    try {
      const response = await this.httpClient.get(
        `${this.baseUrl}/tracking/state/postalCode/${query}`
      );

      return JSON.stringify(response.data());
    } catch (err) {
      throw err;
    }
  }
}

export default ApiStateReader;
