class UploadPhotoError extends Error {
  public fileName: string;

  constructor(fileName: string) {
    super('Error uploading photo');
    this.fileName = fileName;

    Object.setPrototypeOf(this, UploadPhotoError.prototype);
  }
}

export default UploadPhotoError;
