import TooLargePhotoError from '@src/core/domain/Photos/TooLargePhotoError';
class Photo {
  static MAX_FILE_SIZE = 8 * 1024 * 1024;
  readonly file: File;

  constructor(file: File) {
    this.validate(file);
    this.file = file;
  }

  private validate(file: File): void {
    if (file.size > Photo.MAX_FILE_SIZE) {
      throw new TooLargePhotoError(file.name);
    }
  }
}

export default Photo;
