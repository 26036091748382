import HiddenCategoriesRepositoryInterface from '@src/core/domain/Categories/HiddenCategoriesRepositoryInterface';
import I18n from '@src/core/I18n';

class HiddenCategoriesRepository implements HiddenCategoriesRepositoryInterface {
  LOCALE = I18n.intl().locale;
  fetchAll(): string[] {
    return require(`@src/data/hiddenCategories/${this.LOCALE}.json`);
  }
}

export default HiddenCategoriesRepository;
