import CategoryRepositoryInterface from '@src/core/domain/Categories/CategoryRepositoryInterface';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';

class FetchAllCategories {
  private repository: CategoryRepositoryInterface;

  constructor(categoryRepository: CategoryRepositoryInterface) {
    this.repository = categoryRepository;
  }

  execute(): Promise<CategoryGroupTree> {
    return this.repository.fetchAll();
  }
}

export default FetchAllCategories;
