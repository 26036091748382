import Photo from '@src/core/domain/Photos/Photo';
import PhotoRepositoryInterface from '@src/core/domain/Photos/PhotoRepositoryInterface';
import UploadedPhoto from '@src/core/domain/Photos/UploadedPhoto';
class UploadPhoto {
  private repository: PhotoRepositoryInterface;

  constructor(repository: PhotoRepositoryInterface) {
    this.repository = repository;
  }

  async execute(file: File): Promise<UploadedPhoto> {
    return this.repository.upload(new Photo(file));
  }
}

export default UploadPhoto;
