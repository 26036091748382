import InvalidPhoneNumberError from '@src/core/domain/CustomerContactData/InvalidPhoneNumberError';
import PhoneNumberValidator from '@src/core/domain/CustomerContactData/PhoneNumberValidator';

class NDigitsPhoneNumberValidator implements PhoneNumberValidator {
  constructor(private digits: number) {
    this.digits = digits;
  }

  validate(phoneNumber: string): void {
    const pattern = new RegExp(`^\\d{${this.digits}}$`);
    const phoneNumberWithoutBlanks = phoneNumber.replace(/[^\d]+/g, '');

    const isValidPhoneNumber = pattern.test(phoneNumberWithoutBlanks);

    if (!isValidPhoneNumber) {
      throw new InvalidPhoneNumberError();
    }
  }
}

export default NDigitsPhoneNumberValidator;
