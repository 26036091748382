import CheckProfessionalsWithAutomaticPurchases from '@src/core/useCases/CheckProfessionalsWithAutomaticPurchases/CheckProfessionalsWithAutomaticPurchases';
import Factory from '@src/core/Factory';

class CheckProfessionalsWithAutomaticPurchasesFactory {
  private static _instance: CheckProfessionalsWithAutomaticPurchases | undefined;

  static create(): CheckProfessionalsWithAutomaticPurchases {
    if (this._instance === undefined) {
      this._instance = new CheckProfessionalsWithAutomaticPurchases(
        Factory.serviceRequestBaseUrl(),
        Factory.newHttpClient()
      );
    }

    return this._instance;
  }

  static setInstance(instance: CheckProfessionalsWithAutomaticPurchases): void {
    this._instance = instance;
  }
}

export default CheckProfessionalsWithAutomaticPurchasesFactory;
