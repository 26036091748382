import React from 'react';
import { useIntl } from 'react-intl';

import AsyncAutocomplete, { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import JobTypeAutocompleteOption from '@src/ui/apps/ServiceRequest/Steps/JobTypeStep/JobTypeAutocompleteOption';
import SearchCategoriesFactory from '@src/core/useCases/Category/SearchCategoriesFactory';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import Category, { CategoryType } from '@src/core/domain/Categories/Category';
import FetchHiddenCategoriesFactory from '@src/core/useCases/Category/FetchHiddenCategoriesFactory';

type JobTypeAutocompleteProps = {
  defaultSelected?: JobTypeAutocompleteOption;
  onChange: (option?: AutocompleteOption) => void;
  categoriesTree: CategoryGroupTree;
  showDesignVariant?: boolean;
};

function JobTypeAutocomplete({
  onChange,
  defaultSelected,
  categoriesTree,
  showDesignVariant,
}: JobTypeAutocompleteProps): React.ReactElement {
  const onSearchHandler = async (query: string): Promise<JobTypeAutocompleteOption[]> => {
    const searchCategories = SearchCategoriesFactory.create();
    const categories = await searchCategories.execute(query);
    const filterHiddenJobTypes = (
      jobTypes: Category[],
      hiddenCategoriesIds: string[]
    ): Category[] => {
      return jobTypes.filter((jobType) => !hiddenCategoriesIds.includes(jobType.id));
    };
    const hiddenCategories = FetchHiddenCategoriesFactory.create().execute();
    const visibleCategories = filterHiddenJobTypes(categories, hiddenCategories);

    const options: JobTypeAutocompleteOption[] = visibleCategories.map((category) => {
      let parentCategoryName = '';

      if (category.type === CategoryType.JOB_TYPE) {
        const parentCategory = categoriesTree.findCategoryByJobTypeId(category.id);
        parentCategoryName = parentCategory.name;
      }

      return new JobTypeAutocompleteOption(
        category.id,
        category.name,
        category.type,
        parentCategoryName,
        showDesignVariant
      );
    });

    return Promise.resolve(options);
  };

  const intl = useIntl();

  return (
    <AsyncAutocomplete
      filterBy={(option, props) => {
        return true;
      }}
      id="jobTypeSearcher"
      onChange={onChange}
      onSearch={onSearchHandler}
      defaultSelected={defaultSelected}
      placeholder={intl.formatMessage({
        id: 'serviceRequestForm.jobTypeStep.jobTypeAutocomplete.placeholder',
        defaultMessage: 'Ej: Pintores, Carpinteros, Reformas',
        description: 'Placeholder que se muestra en el campo cuando aún no se ha escrito nada',
      })}
      showDesignVariant={showDesignVariant}
    />
  );
}

export default JobTypeAutocomplete;
