import React, { ReactNode } from 'react';
import { createIntl, createIntlCache, IntlProvider, IntlShape } from 'react-intl';
import { FormatXMLElementFn } from 'intl-messageformat';

const DEFAULT_LOCALE = 'es-ES';

class I18n {
  private static _intl: IntlShape | undefined;
  private static _locale: string | undefined;
  private static _messages: Record<string, string> | undefined;

  public static configureLocales(locale?: string, messages?: Record<string, string>): void {
    I18n._locale = locale;
    I18n._messages = messages;
    I18n._intl = undefined;
  }

  public static intl(): IntlShape {
    if (!I18n._intl) {
      const cache = createIntlCache();

      I18n._intl = createIntl(
        {
          locale: I18n.locale(),
          defaultLocale: DEFAULT_LOCALE,
          messages: I18n.messages(),
        },
        cache
      );
    }
    return I18n._intl;
  }

  public static locale(): string {
    return I18n._locale || DEFAULT_LOCALE;
  }

  public static messages(): Record<string, string> {
    if (I18n._messages) {
      return I18n._messages;
    }

    return require(`@src/locales/${I18n.locale()}.json`);
  }
}

const Bold: FormatXMLElementFn<ReactNode, React.ReactElement> = (child) => <strong>{child}</strong>;
const Underline: FormatXMLElementFn<ReactNode, React.ReactElement> = (child) => <u>{child}</u>;
const breakLine: FormatXMLElementFn<ReactNode, React.ReactElement> = (child) => <br>{child}</br>;

const WithIntl = (props: React.PropsWithChildren<unknown>): React.ReactElement => (
  <IntlProvider
    locale={I18n.locale()}
    defaultLocale={DEFAULT_LOCALE}
    messages={I18n.messages()}
    defaultRichTextElements={{
      b: Bold,
      u: Underline,
      br: breakLine,
    }}
  >
    {props.children}
  </IntlProvider>
);

export default I18n;
export { WithIntl, DEFAULT_LOCALE };
