import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import confidenceWarrantyBrandIcon from '@src/ui/apps/ServiceRequest/warranty-habitissimo-02.svg';
import Image from '@src/ui/components/Image/Image';
import I18n from '@src/core/I18n';

export default function ConfidenceWarrantyBrandIndicator(): React.ReactElement {
  const LOCALE = I18n.intl().locale;

  if (LOCALE !== 'es-ES') {
    return <></>;
  }
  return (
    <p
      data-testid="confidenceText"
      className={styles['serviceRequestForm__confidence-warranty-text']}
      data-vwo="confidenceText"
    >
      <Image src={confidenceWarrantyBrandIcon} />
      <span>
        <FormattedMessage
          id="serviceRequestForm.confidenceWarranty"
          defaultMessage="Profesionales cubiertos por <breakLine></breakLine>las <warrantyColor>garantías habitissimo</warrantyColor>"
          description="Texto que aparece en la parte inferior del formulario durante todos los pasos"
          values={{
            breakLine: function breakLine(text: string) {
              return <br></br>;
            },
            warrantyColor: function warrantyColor(text: string) {
              return <span>{text}</span>;
            },
          }}
        />
      </span>
    </p>
  );
}
