import Location from './Location';
import LocationNotFoundError from './LocationNotFoundError';

class Locations {
  constructor(private _items: Location[] = []) {}

  static fromJsonArray(data: unknown): Locations {
    type LocationData = {
      id: string;
      name: string;
      parentDivisionName?: string;
    };

    const locations = (data as Array<LocationData>).map(
      (item: LocationData) => new Location(item.id, item.name, item.parentDivisionName)
    );
    return new Locations(locations);
  }

  toArray(): Location[] {
    return this._items;
  }

  length(): number {
    return this._items.length;
  }

  first(): Location {
    return this._items[0];
  }

  getById(id: string): Location {
    const location = this._items.find((location) => location.id === id);
    if (location === undefined) {
      throw new LocationNotFoundError();
    }
    return location;
  }
}

export default Locations;
