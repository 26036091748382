class HttpError extends Error {
  private _code: string;
  public message: string;

  constructor(code: string, message: string) {
    super(message);

    Object.setPrototypeOf(this, HttpError.prototype);

    this._code = code;
    this.message = message;
  }

  code(): string {
    return this._code;
  }

  isNotFoundError(): boolean {
    return this._code === '404';
  }
}

export default HttpError;
