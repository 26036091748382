import Factory from '@src/core/Factory';
import FetchAllCategories from '@src/core/useCases/Category/FetchAllCategories';

class FetchAllCategoriesFactory {
  private static _instance: FetchAllCategories | undefined;

  static create(): FetchAllCategories {
    if (this._instance === undefined) {
      this._instance = new FetchAllCategories(Factory.newCategoryRepository());
    }

    return this._instance;
  }

  static setInstance(instance: FetchAllCategories): void {
    this._instance = instance;
  }
}

export default FetchAllCategoriesFactory;
