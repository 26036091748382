import Factory from '@src/core/Factory';
import FetchLocations from '@src/core/useCases/Locations/FetchLocations';

class FetchLocationsFactory {
  private static _instance: FetchLocations | undefined;

  static create(): FetchLocations {
    if (this._instance === undefined) {
      this._instance = new FetchLocations(Factory.newLocationRepository());
    }

    return this._instance;
  }

  static setInstance(instance: FetchLocations): void {
    this._instance = instance;
  }
}

export default FetchLocationsFactory;
