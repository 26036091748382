import Factory from '@src/core/Factory';
import UploadPhoto from './UploadPhoto';

class UploadPhotoFactory {
  private static _instance: UploadPhoto | undefined;

  static create(): UploadPhoto {
    if (this._instance === undefined) {
      this._instance = new UploadPhoto(Factory.newPhotoRepository());
    }

    return this._instance;
  }

  static setInstance(instance: UploadPhoto): void {
    this._instance = instance;
  }
}

export default UploadPhotoFactory;
