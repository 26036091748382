import React, { ChangeEvent, createRef, useEffect } from 'react';
import AnswerOption, { Answer } from '@src/core/domain/Answer';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import Input from '@src/ui/components/Input/Input';
import Question, { AnswerType } from '@src/core/domain/Questions/Question';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import styles from './QuestionStep.module.scss';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { QuestionStepRendered } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import {
  adaptValidator,
  notEmptyBoolValidation,
  notEmptyValidation,
} from '@src/ui/helpers/form/validations';
import { ServiceRequestFormContext } from '../../ServiceRequestFormContext';
import { useForm, UseFormMethods } from 'react-hook-form';
import classNames from 'classnames';

export type QuestionStepsData = {
  answers: Record<string, Answer>;
};

type QuestionStepProp = {
  formControls?: React.ReactChild;
  question: Question;
  changeJobTypeLink?: React.ReactNode;
  showDesignVariant?: boolean;
};

function QuestionStep({
  formControls,
  question,
  changeJobTypeLink,
  showDesignVariant,
}: QuestionStepProp): React.ReactElement {
  const form = useForm<Answer>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const helper = useFormHelper(form);
  const refForm = createRef<HTMLFormElement>();

  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const wrappedSubmit = (data: Answer) => {
    const answers = { ...formData.answers, [data.questionId]: data };
    handleStepCompleted({ answers });
  };

  useEffect(() => {
    eventBusSingleton.fireEvent(new QuestionStepRendered(question));
  }, []);

  const stepTitleClass = `step-title${showDesignVariant ? ' step-title--showDesignVariant' : ''}`;

  return (
    <form data-testid="questions-step" onSubmit={form.handleSubmit(wrappedSubmit)} ref={refForm}>
      <Input type="hidden" name="questionId" value={question.id} inputRef={form.register({})} />

      <StepTitle data-testid="question" className={stepTitleClass}>
        {question.question}
      </StepTitle>

      {!showDesignVariant && changeJobTypeLink}

      <div
        className={classNames(styles.QuestionStep__wrapper, {
          [styles['QuestionStep__wrapper--showDesignVariant']]: showDesignVariant,
        })}
      >
        {helper.errorMessage('answerContent') && (
          <ValidationError showDesignVariant={showDesignVariant}>
            {helper.errorMessage('answerContent')}
          </ValidationError>
        )}

        {question.answerType === AnswerType.SINGLE_ANSWER && (
          <SingleAnswerQuestion
            question={question}
            defaultValues={formData as QuestionStepsData}
            form={form}
            triggerSubmit={() => {
              refForm?.current?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
            showDesignVariant={showDesignVariant}
          />
        )}

        {question.answerType === AnswerType.MULTIPLE_ANSWERS && (
          <MultipleAnswersQuestion
            question={question}
            defaultValues={formData as QuestionStepsData}
            form={form}
            showDesignVariant={showDesignVariant}
          />
        )}

        {question.answerType === AnswerType.FREE_TEXT && (
          <FreeTextQuestion
            question={question}
            defaultValues={formData as QuestionStepsData}
            form={form}
            showDesignVariant={showDesignVariant}
          />
        )}
      </div>

      {formControls}
    </form>
  );
}

type SingleAnswerQuestionProp = {
  question: Question;
  defaultValues: Partial<QuestionStepsData>;
  form: UseFormMethods<Answer>;
  triggerSubmit: () => void;
  showDesignVariant?: boolean;
};

function SingleAnswerQuestion({
  question,
  defaultValues,
  form,
  triggerSubmit,
  showDesignVariant,
}: SingleAnswerQuestionProp): React.ReactElement {
  const previousValue = defaultValues.answers && defaultValues.answers[question.id];

  return (
    <>
      {question.answerOptions.map((answerOption: AnswerOption) => (
        <ButtonLabel
          key={answerOption.id}
          className={classNames(styles.QuestionStep__entry, {
            [styles['QuestionStep__entry--showDesignVariant']]: showDesignVariant,
          })}
          showDesignVariant={showDesignVariant}
        >
          <label htmlFor={answerOption.id}>
            <Input
              inputRef={form.register({ validate: adaptValidator(notEmptyBoolValidation) })}
              type="radio"
              name="answerContent"
              id={answerOption.id}
              value={answerOption.id}
              defaultChecked={previousValue?.answerContent === answerOption.id}
              data-testid="answerOption"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                triggerSubmit();
              }}
            />
            {answerOption.answer}
          </label>
        </ButtonLabel>
      ))}
    </>
  );
}

type MultipleAnswersQuestionProp = {
  question: Question;
  defaultValues: Partial<QuestionStepsData>;
  form: UseFormMethods<Answer>;
  showDesignVariant?: boolean;
};

function MultipleAnswersQuestion({
  question,
  defaultValues,
  form,
  showDesignVariant,
}: MultipleAnswersQuestionProp): React.ReactElement {
  const previousValue = defaultValues.answers && defaultValues.answers[question.id];
  return (
    <>
      {question.answerOptions.map((answerOption: AnswerOption) => (
        <ButtonLabel
          key={answerOption.id}
          className={classNames(styles.QuestionStep__entry, {
            [styles['QuestionStep__entry--showDesignVariant']]: showDesignVariant,
          })}
          showDesignVariant={showDesignVariant}
        >
          <label htmlFor={answerOption.id}>
            <Input
              inputRef={form.register({ validate: adaptValidator(notEmptyBoolValidation) })}
              type="checkbox"
              name="answerContent"
              id={answerOption.id}
              value={answerOption.id}
              defaultChecked={previousValue?.answerContent.includes(answerOption.id)}
              data-testid="answerOption"
            />
            {answerOption.answer}
          </label>
        </ButtonLabel>
      ))}
    </>
  );
}

type FreeTextQuestionProp = {
  question: Question;
  defaultValues: Partial<QuestionStepsData>;
  form: UseFormMethods<Answer>;
  showDesignVariant?: boolean;
};

function FreeTextQuestion({
  question,
  defaultValues,
  form,
  showDesignVariant,
}: FreeTextQuestionProp): React.ReactElement {
  const previousValue = defaultValues.answers && defaultValues.answers[question.id];

  return (
    <>
      <Input
        inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
        type="text"
        name="answerContent"
        id={question.id}
        data-testid="answerOption"
        defaultValue={previousValue?.answerContent as string}
        showDesignVariant={showDesignVariant}
      />
    </>
  );
}

export default QuestionStep;
