import { BrowserStorage } from './BrowserStorage';

export class NullBrowserStorage implements BrowserStorage {
  setItem(key: string, data: string): void {}

  getItem(key: string): null {
    return null;
  }

  removeItem(key: string): void {}
}
