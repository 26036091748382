import PostalCodeValidator from './PostalCodeValidator';

class PostalCode {
  private _postalCode: string;

  constructor(postalCode: string, postalCodeValidator: PostalCodeValidator) {
    postalCodeValidator.validate(postalCode);

    this._postalCode = postalCode;
  }

  public toString(): string {
    return this._postalCode;
  }
}

export default PostalCode;
