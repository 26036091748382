import React, { forwardRef, ReactNode } from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';
import Image from '@src/ui/components/Image/Image';

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type Props = {
  variant?: ButtonTypes;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
  children?: ReactNode;
  className?: string;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  icon?: string;
  iconImg?: string;
  showDesignVariant?: boolean;
  isHidden?: boolean;
};

type ButtonType = Props;

const Button = forwardRef<HTMLButtonElement, ButtonType>(
  (
    {
      variant,
      isLoading,
      children,
      className,
      disabled,
      icon,
      iconImg,
      showDesignVariant,
      isHidden,
      ...restProps
    }: ButtonType,
    ref
  ) => {
    const buttonVariantClass = `button--${variant}`;
    const componentClassName = cx(styles.button, styles[buttonVariantClass], className, {
      [styles['button--is-loading']]: isLoading,
      [styles['button--showDesignVariant']]: showDesignVariant,
      [styles['button--showDesignVariant--hidden']]: showDesignVariant && isHidden,
      [styles['button--showDesignVariant--icon']]:
        showDesignVariant && iconImg && restProps['data-testid'] === 'backButton',
      button: true,
    });

    return (
      <button
        className={componentClassName}
        disabled={disabled || isLoading}
        {...restProps}
        ref={ref}
      >
        {icon && <i className={`icon icon-${icon} ${styles.button__icon}`}></i>}
        {iconImg && !icon && <Image src={iconImg} alt="icon" className={styles.button__icon} />}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  variant: ButtonTypes.PRIMARY,
  type: 'button',
};

export default Button;
