import ForwardServiceRequestToBusiness from '@src/core/useCases/ServiceRequest/ForwardServiceRequestToBusiness';
import Factory from '@src/core/Factory';

class ForwardServiceRequestToBusinessFactory {
  private static _instance: ForwardServiceRequestToBusiness | undefined;

  static create(): ForwardServiceRequestToBusiness {
    if (this._instance === undefined) {
      this._instance = new ForwardServiceRequestToBusiness(
        Factory.serviceRequestBaseUrl(),
        Factory.newHttpClient()
      );
    }

    return this._instance;
  }

  static setInstance(instance: ForwardServiceRequestToBusiness): void {
    this._instance = instance;
  }
}

export default ForwardServiceRequestToBusinessFactory;
