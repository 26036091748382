import React, { FunctionComponent } from 'react';
import styles from './Link.module.scss';
import classNames from 'classnames';

type LinkProps = {
  children: React.ReactNode;
  href?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void)
    | undefined;
  'data-testid'?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  showDesignVariant?: boolean;
};

const Link: FunctionComponent<LinkProps> = (props: LinkProps) => {
  const buttonClassName = classNames(styles['link--button'], {
    [styles['link--button--showDesignVariant']]: props.showDesignVariant,
    [styles['link--button--a--showDesignVariant']]:
      props.showDesignVariant && props['data-testid'] === 'revealLocationSearch',
  });
  const aClassName = classNames(styles.link, {
    [styles['link--showDesignVariant']]: props.showDesignVariant,
  });

  if (props.href) {
    return (
      <a
        href={props.href}
        onClick={props.onClick}
        data-testid={props['data-testid']}
        className={aClassName}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      type="button"
      onClick={props.onClick}
      className={buttonClassName}
      data-testid={props['data-testid']}
    >
      {props.children}
    </button>
  );
};

export default Link;
