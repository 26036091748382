import React, { FunctionComponent } from 'react';
import styles from './Alert.module.scss';
import cx from 'classnames';
import Image from '@src/ui/components/Image/Image';
import infoCircleIcon from './static/info-circle-o.svg';
import bannerCircleIcon from './static/banner-circle-o.svg';
import banIcon from './static/ban-o.svg';
import checkIcon from './static/check-o.svg';
import warningIcon from './static/warning-o.svg';

export enum AlertTypes {
  SUCCESS = 'success',
  INFORMATION = 'info',
  BANNER = 'banner',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum AlertSize {
  SMALL = 'size-small',
  MEDIUM = 'size-medium',
}

type AlertProps = {
  variant: AlertTypes;
  size?: AlertSize;
  icon?: React.ReactElement | null;
  children?: React.ReactNode;
  showDesignVariant?: boolean;
};

const defaultIcons: Record<AlertTypes, React.ReactElement> = {
  [AlertTypes.INFORMATION]: <Image src={infoCircleIcon} alt="icon" />,
  [AlertTypes.BANNER]: <Image src={bannerCircleIcon} alt="icon" />,
  [AlertTypes.ERROR]: <Image src={banIcon} alt="icon" />,
  [AlertTypes.SUCCESS]: <Image src={checkIcon} alt="icon" />,
  [AlertTypes.WARNING]: <Image src={warningIcon} alt="icon" />,
};

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  const variantClass = `alert--${props.variant}`;
  const className = cx(styles.alert, styles[variantClass], [
    {
      [styles[`alert--${props.size}`]]: props.size,
      [styles['alert--info--showDesignVariant']]:
        props.showDesignVariant && props.variant === AlertTypes.INFORMATION,
      [styles['alert--error--showDesignVariant']]:
        props.showDesignVariant && props.variant === AlertTypes.ERROR,
    },
  ]);
  const iconWrapperClassName = cx(styles['alert__icon-wrapper'], {
    [styles['alert__icon-wrapper--showDesignVariant']]: props.showDesignVariant,
  });
  const icon = props.icon === null ? null : props.icon || defaultIcons[props.variant];
  const datavwo = 'alert';

  return (
    <div role="alert" className={className} data-vwo={datavwo}>
      {icon && <div className={iconWrapperClassName}>{icon}</div>}
      <div className={styles.alert__content}>{props.children}</div>
    </div>
  );
};

export default Alert;
