import React from 'react';
import styles from './Loading.module.scss';
import cx from 'classnames';

function Loading(): React.ReactElement {
  return (
    <div className={styles.loading}>
      <div className={styles.loading__blocks}>
        <span className={cx(styles.loading__element, styles['loading__element--first'])}></span>
        <span className={cx(styles.loading__element, styles['loading__element--second'])}></span>
        <span className={cx(styles.loading__element, styles['loading__element--third'])}></span>
      </div>
    </div>
  );
}

export default Loading;
