import React, { ChangeEvent, createRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { FormattedMessage } from 'react-intl';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import styles from '@src/ui/apps/ServiceRequest/Steps/HOExperiencePreferenceStep/HOExperiencePreferenceStep.module.scss';
import Input from '@src/ui/components/Input/Input';
import { adaptValidator, notEmptyValidation } from '@src/ui/helpers/form/validations';
import Image from '@src/ui/components/Image/Image';
import ServiceRequestIcon from '@src/ui/apps/ServiceRequest/Steps/HOExperiencePreferenceStep/receive-quotes.svg';
import DirectoryIcon from '@src/ui/apps/ServiceRequest/Steps/HOExperiencePreferenceStep/directory-choose-01.svg';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  HOExperiencePreferenceClickedDirectory,
  HOExperiencePreferenceClickedReject,
  HOExperiencePreferenceStepRendered,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';

export type HOExperiencePreferenceStepProps = {
  formControls?: React.ReactChild;
  order?: string;
};

export type HOExperiencePreferenceStepData = {
  isDirectoryExperience: string | undefined;
};

function HOExperiencePreferenceStep({
  formControls,
  order,
}: HOExperiencePreferenceStepProps): React.ReactElement {
  const { handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const form = useForm<HOExperiencePreferenceStepData>({});
  const refForm = createRef<HTMLFormElement>();
  const helper = useFormHelper(form);

  order = order ?? 'leadgen down';
  useEffect(() => {
    eventBusSingleton.fireEvent(new HOExperiencePreferenceStepRendered(order));
  }, []);

  function getDirectoryExperienceSelectedButtonLabel() {
    return (
      <>
        <Image
          className={styles.HOExperiencePreferenceStep__image}
          src={DirectoryIcon}
          alt={'Directory experience'}
        />
        <ButtonLabel className={styles.HOExperiencePreferenceStep__entry}>
          <label htmlFor="directoryExperienceSelected">
            <Input
              inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
              type="radio"
              name="isDirectoryExperience"
              id="directoryExperienceSelected"
              value="yes"
              data-testid="directoryExperienceSelected"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                eventBusSingleton.fireEvent(new HOExperiencePreferenceClickedDirectory());
                handleStepCompleted(form.getValues() as HOExperiencePreferenceStepData);
              }}
            />
            <FormattedMessage
              id="serviceRequestForm.HOExperiencePreferenceStep.option.directoryExperienceSelected"
              defaultMessage="Ir a un listado y contactar con los profesionales"
              description="El homeowner selecciona la opción de ir al directorio"
            />
          </label>
        </ButtonLabel>
      </>
    );
  }

  function getServiceRequestSelectedButtonLabel() {
    return (
      <>
        <Image
          className={styles.HOExperiencePreferenceStep__image}
          src={ServiceRequestIcon}
          alt={'Service request experience'}
        />
        <ButtonLabel className={styles.HOExperiencePreferenceStep__entry}>
          <label htmlFor="serviceRequestSelected">
            <Input
              inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
              type="radio"
              name="isDirectoryExperience"
              id="serviceRequestSelected"
              value="no"
              data-testid="serviceRequestSelected"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                eventBusSingleton.fireEvent(new HOExperiencePreferenceClickedReject());
                handleStepCompleted(form.getValues() as HOExperiencePreferenceStepData);
              }}
            />
            <FormattedMessage
              id="serviceRequestForm.HOExperiencePreferenceStep.option.serviceRequestSelected"
              defaultMessage="Completar una solicitud y que los profesionales contacten conmigo"
              description="El homeowner prefiereguir con la peticion de presupuesto"
            />
          </label>
        </ButtonLabel>
      </>
    );
  }

  return (
    <>
      <StepTitle className="step-title" data-testid="step-title">
        <FormattedMessage
          id="serviceRequestForm.HOExperiencePreferenceStep.header"
          defaultMessage="¿Cómo prefieres encontrar profesionales?"
          description="Título del paso para escoger la experiencia preferida por el homeowner"
        />
      </StepTitle>
      <form
        onSubmit={form.handleSubmit(handleStepCompleted)}
        data-testid="homeownerExperienceServiceRequestForm"
        ref={refForm}
      >
        <div className={styles.HOExperiencePreferenceStep__wrapper}>
          {helper.errorMessage('isDirectoryExperience') && (
            <ValidationError>{helper.errorMessage('isDirectoryExperience')}</ValidationError>
          )}

          {order === 'leadgen down' && getDirectoryExperienceSelectedButtonLabel()}

          {getServiceRequestSelectedButtonLabel()}

          {order === 'leadgen up' && getDirectoryExperienceSelectedButtonLabel()}
        </div>
        {formControls}
      </form>
    </>
  );
}

export default HOExperiencePreferenceStep;
