import React from 'react';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { FormattedMessage } from 'react-intl';
import styles from './FindingProfessionalsInYourAreaStep.module.scss';
import formStyles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import cx from 'classnames';
import FapIcon from './find_a_pro.svg';
import Icon from '@src/ui/components/Icon/Icon';
import Image from '@src/ui/components/Image/Image';

export const FindingProfessionalsInYourAreaStep = (): React.ReactElement => {
  return (
    <div
      className={cx(styles.findAPro, formStyles['serviceRequestForm'])}
      data-testid="finding-pros-in-your-area"
    >
      <StepTitle className="step-title" data-testid="step-title">
        <FormattedMessage
          id="serviceRequestForm.findAProStep.header"
          defaultMessage="Buscando profesionales..."
          description="Título del paso mientras buscamos profesionales en la localidad que realicen el tipo de trabajo seleccionado"
        />
      </StepTitle>
      <Image
        className={styles.findAPro__image}
        src={FapIcon}
        alt={'Finding professionals in your area'}
      />

      <p className={styles.findAPro__description}>
        <Icon className={styles.findAPro__spinner} name="spinner-o" animate={true} />
        <FormattedMessage
          id="serviceRequestForm.findAProStep.description"
          defaultMessage="Comprobando profesionales en tu zona..."
          description="Descripción del paso mientras buscamos profesionales en la localidad que realicen el tipo de trabajo seleccionado"
        />
      </p>
    </div>
  );
};
