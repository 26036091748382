import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import LabelledInput, { LabelledInputProps } from '@src/ui/components/LabelledInput/LabelledInput';
import {
  adaptValidator,
  combine,
  notEmptyValidation,
  phoneNumberValidation,
  termsAcceptedValidation,
} from '@src/ui/helpers/form/validations';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import CheckBox from '@src/ui/components/Input/CheckBox';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  ContactDataStepRendered,
  ContactStepErrors,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import Icon from '@src/ui/components/Icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import TermsOfUseLink from './TermsOfUseLink';
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import { Controller, useForm } from 'react-hook-form';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { getLocalizedService } from '@src/ui/helpers/localization/index';
import { Mask, withMask } from '@src/ui/helpers/masks/withMask';
import EmailAutocomplete from '@src/ui/components/EmailAutocomplete/EmailAutocomplete';
import ThirdParty from './ThirdParty';
import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import classNames from 'classnames';

export type CustomerContactStepData = {
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  termsAndConditions: boolean;
  thirdParty: boolean;
  loggedUser?: boolean;
};

function isKeyOfCustomerContactStepData(
  keyInput: string
): keyInput is keyof CustomerContactStepData {
  return ['customerName', 'customerEmail', 'customerPhone', 'termsAndConditions'].includes(
    keyInput
  );
}

type CustomerContactStepProps = {
  formControls?: React.ReactChild;
  errors?: Record<string, string>;
  uploadingPhotosCount?: number;
  showDesignVariant?: boolean;
};

const MaskedLabelledInput = withMask<LabelledInputProps>(LabelledInput);

export const CustomerContactStep: FunctionComponent<CustomerContactStepProps> = ({
  formControls,
  errors = {},
  uploadingPhotosCount = 0,
  showDesignVariant,
}: CustomerContactStepProps) => {
  const form = useForm<CustomerContactStepData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const helper = useFormHelper(form);
  const { formData, handleSubmit } = React.useContext(ServiceRequestFormContext);

  const refs = {
    customerName: useRef<HTMLInputElement | null>(null),
    customerEmail: useRef<HTMLInputElement | null>(null),
    customerPhone: useRef<HTMLInputElement | null>(null),
    termsAndConditions: useRef<HTMLInputElement | null>(null),
  };

  const isLoggedUser = formData.loggedUser;
  const [shouldShowEmailWarningMessage, setShouldShowEmailWarningMessage] = useState(false);

  useEffect(() => {
    eventBusSingleton.fireEvent(new ContactDataStepRendered());
  }, []);

  useEffect(() => {
    Object.entries(errors || {}).forEach(([field, error]) => {
      if (isKeyOfCustomerContactStepData(field)) {
        helper.setError(field as keyof CustomerContactStepData, error);
      }
    });
  }, [errors]);

  useEffect(() => {
    const arrayValidator: string[] = [];
    if (undefined !== form.errors.customerEmail) {
      arrayValidator.push('email:' + form.errors.customerEmail.message);
    }
    if (undefined !== form.errors.customerName) {
      arrayValidator.push('name:' + form.errors.customerName.message);
    }
    if (undefined !== form.errors.customerPhone) {
      arrayValidator.push('phone:' + form.errors.customerPhone.message);
    }
    if (undefined !== form.errors.termsAndConditions) {
      arrayValidator.push('terms:' + form.errors.termsAndConditions.message);
    }
    if (arrayValidator.length > 0) {
      eventBusSingleton.fireEvent(new ContactStepErrors(arrayValidator));
    }

    if (showDesignVariant) {
      for (const key of Object.keys(refs)) {
        if (form.errors[key as keyof CustomerContactStepData]) {
          (refs[key as keyof typeof refs].current as HTMLElement)?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          break;
        }
      }
    }
  }, [form.errors, errors]);

  useEffect(() => {
    refs.customerName.current?.focus();
  }, []);

  const intl = useIntl();
  const anyCharacter = { mask: /.*/ };
  const phoneMaskProps = getLocalizedService<Mask>('phoneMaskProps') || anyCharacter;

  const stepTitleClass = `step-title${showDesignVariant ? ' step-title--showDesignVariant' : ''}`;

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className={classNames({
        [styles['serviceRequestForm__customerContactStep--showDesignVariant']]: showDesignVariant,
      })}
    >
      <StepTitle className={stepTitleClass} data-vwo="stepTitle">
        {showDesignVariant ? (
          <FormattedMessage
            id="serviceRequestForm.customerContactStepDesignVariant.header"
            defaultMessage="¿Dónde te gustaría recibir las propuestas?"
            description="Título del paso para que el usuario entre sus datos de contacto de la nueva propuesta de diseño"
          />
        ) : (
          <FormattedMessage
            id="serviceRequestForm.customerContactStep.header"
            defaultMessage="Tus datos de contacto"
            description="Título del paso para que el usuario entre sus datos de contacto"
          />
        )}
      </StepTitle>
      <LabelledInput
        inputRef={(r) => {
          form.register(r, { validate: adaptValidator(notEmptyValidation) });
          refs.customerName.current = r;
        }}
        labelText={
          !showDesignVariant
            ? intl.formatMessage({
                id: 'serviceRequestForm.customerContactStep.nameField.label',
                defaultMessage: 'Nombre',
                description:
                  'Texto para indicar el campo Nombre de los datos de contacto del usuario',
              })
            : intl.formatMessage({
                id: 'serviceRequestForm.customerContactStep.newDesign.nameField.label',
                defaultMessage: 'Tu nombre',
                description:
                  'Texto para indicar el campo Nombre de los datos de contacto del usuario',
              })
        }
        inputName="customerName"
        inputId="customerName"
        inputStatus={helper.inputStatus('customerName')}
        errorMessage={helper.errorMessage('customerName')}
        inputDefaultValue={formData.customerName}
        inputPlaceholder={
          showDesignVariant
            ? intl.formatMessage({
                id: 'serviceRequestForm.customerContactStep.nameField.newDesign.placeholder',
                defaultMessage: 'Ej: Ana',
                description: 'Placeholder de ejemplo para el campo nombre del usuario',
              })
            : ''
        }
        showDesignVariant={showDesignVariant}
        data-testid="customerName"
        data-vwo="customerName"
        data-vwo-label="customerNameLabeled"
      />
      {isLoggedUser ? (
        <LabelledInput
          labelText={intl.formatMessage({
            id: 'serviceRequestForm.customerContactStep.emailField.label',
            defaultMessage: 'Email',
            description: 'Texto para indicar el campo Email de los datos de contacto del usuario',
          })}
          inputName="customerEmail"
          inputId="customerEmail"
          readOnly={true}
          warningMessage={
            shouldShowEmailWarningMessage
              ? intl.formatMessage({
                  id: 'serviceRequestForm.customerContactStep.emailReadOnly.warning',
                  defaultMessage:
                    'Este campo no se puede editar en este momento. Puedes actualizar tu dirección de email en tu área privada.',
                  description:
                    'Mensaje de advertencia para indicar que el campo Email no es editable durante el proceso.',
                })
              : ''
          }
          inputDefaultValue={formData.customerEmail}
          data-testid="emailReadOnly"
          inputOnClick={() => setShouldShowEmailWarningMessage(true)}
          inputOnBlur={() => setShouldShowEmailWarningMessage(false)}
          showDesignVariant={showDesignVariant}
        />
      ) : (
        <EmailAutocomplete
          form={form}
          formData={formData}
          placeholder={
            showDesignVariant
              ? intl.formatMessage({
                  id: 'serviceRequestForm.customerContactStep.emailField.newDesign.placeholder',
                  defaultMessage: 'Ej: ana@hotmail.com',
                  description: 'Placeholder de ejemplo para el campo email del usuario',
                })
              : ''
          }
          showDesignVariant={showDesignVariant}
        />
      )}
      <Controller
        name="customerPhone"
        control={form.control}
        rules={{ validate: adaptValidator(combine(notEmptyValidation, phoneNumberValidation)) }}
        defaultValue={formData?.customerPhone || ''}
        render={({ onBlur, onChange, value }) => (
          <MaskedLabelledInput
            inputRef={(r) => {
              refs.customerPhone.current = r;
            }}
            {...phoneMaskProps}
            inputType="tel"
            labelText={intl.formatMessage({
              id: 'serviceRequestForm.customerContactStep.phoneField.label',
              defaultMessage: 'Teléfono',
              description:
                'Texto para indicar el campo Teléfono de los datos de contacto del usuario',
            })}
            onAccept={(newValue: string) => {
              onChange(newValue);
            }}
            onComplete={(_) => {
              onBlur();
            }}
            value={value}
            inputOnBlur={onBlur}
            inputName="customerPhone"
            inputId="customerPhone"
            inputStatus={helper.inputStatus('customerPhone')}
            errorMessage={helper.errorMessage('customerPhone')}
            data-testid="customerPhone"
            data-vwo="customerPhone"
            data-vwo-label="customerPhoneLabeled"
            inputPlaceholder={
              showDesignVariant
                ? intl.formatMessage({
                    id:
                      'serviceRequestForm.customerContactStep.phoneNumberField.newDesign.placeholder',
                    defaultMessage: 'Ej: 612345678',
                    description: 'Placeholder de ejemplo para el campo teléfono del usuario',
                  })
                : ''
            }
            showDesignVariant={showDesignVariant}
          />
        )}
      />
      <div
        className={styles['serviceRequestForm__terms-and-conditions']}
        data-vwo="termsAndConditions"
      >
        <CheckBox
          inputRef={(r) => {
            form.register(r, { validate: adaptValidator(termsAcceptedValidation) });
            refs.termsAndConditions.current = r;
          }}
          inputId="termsAndConditions"
          name="termsAndConditions"
          data-testid="termsAndConditions"
          defaultChecked={formData.termsAndConditions}
          label={
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.termsAndConditions.label"
              defaultMessage="He leído y acepto la <privacyPolicy>Política de Privacidad</privacyPolicy> y los <termsOfUse>Términos de uso</termsOfUse>"
              description="Mensaje de aceptación de términos y condiciones"
              values={{
                privacyPolicy: function privacyPolicy(linkText: string) {
                  return (
                    <PrivacyPolicyLink linkText={linkText} showDesignVariant={showDesignVariant} />
                  );
                },
                termsOfUse: function termsOfUse(linkText: string) {
                  return (
                    <TermsOfUseLink linkText={linkText} showDesignVariant={showDesignVariant} />
                  );
                },
              }}
            />
          }
          showDesignVariant={showDesignVariant}
        />
        {helper.hasError('termsAndConditions') && (
          <ValidationError showDesignVariant={showDesignVariant}>
            {helper.errorMessage('termsAndConditions')}
          </ValidationError>
        )}
      </div>
      <div className={styles['serviceRequestForm__third-party']} data-vwo="thirdParty">
        <CheckBox
          inputRef={form.register({})}
          inputId="thirdParty"
          name="thirdParty"
          data-testid="thirdParty"
          defaultChecked={formData.thirdParty}
          label={
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.thirdParty.label"
              defaultMessage="Además, deseo que habitissimo me envíe comunicaciones comerciales sobre productos y/o servicios de terceras entidades con las que mantiene una colaboración. <thirdParty>Más información</thirdParty>"
              description="Mensaje de aceptación de comunicación con terceros"
              values={{
                thirdParty: function thirdParty(linkText: string) {
                  return <ThirdParty linkText={linkText} showDesignVariant={showDesignVariant} />;
                },
              }}
            />
          }
          showDesignVariant={showDesignVariant}
        />
      </div>
      <Alert variant={AlertTypes.INFORMATION} showDesignVariant={showDesignVariant}>
        <span
          className={classNames(styles['serviceRequestForm__contact-alert'], {
            [styles['serviceRequestForm__contact-alert--showDesignVariant']]: showDesignVariant,
          })}
        >
          {!showDesignVariant ? (
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.verification.contactAlert"
              // disable formatjs placeholders rules because it throws an error due to defaultRichTextElements
              // eslint-disable-next-line formatjs/enforce-placeholders
              defaultMessage="Comprueba que tus datos son correctos ya que <b>los profesionales pagan una cuota</b> para poder acceder a tu solicitud de presupuesto."
              description="Mensaje informativo de que el usuario debe introducir datos válidos"
            />
          ) : (
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.verification.contactAlertNewDesign"
              defaultMessage="Comprueba que tus datos son correctos ya que los profesionales pagan una cuota para poder acceder a tu solicitud de presupuesto."
              description="Mensaje informativo de que el usuario debe introducir datos válidos en el nuevo diseño"
            />
          )}
        </span>
      </Alert>
      {uploadingPhotosCount > 0 && (
        <Alert variant={AlertTypes.WARNING} icon={<Icon name="refresh-o" animate={true} />}>
          <span data-testid="uploadingPhotosAlert">
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.uploadingPhotos.warning"
              defaultMessage="{numberOfUploadingPhotos, plural, one {Hay # imagen en proceso de ser subida. Si envías la solicitud ahora la imagen no será incluida, pero podrás añadirla más adelante.} other {Hay # imágenes en proceso de ser subidas. Si envías la solicitud ahora algunas imágenes no serán incluidas, pero podrás añadirlas más adelante.} }"
              description="Mensaje de aviso de que aún se están subiendo las fotos y que puede perderlas si envía el formulario."
              values={{ numberOfUploadingPhotos: uploadingPhotosCount }}
            />
          </span>
        </Alert>
      )}
      {formControls}
    </form>
  );
};
