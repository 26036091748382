import { FormData } from '@src/ui/apps/ServiceRequest/useServiceRequestForm';
import { createContext } from 'react';

export interface ContextProps {
  handleSubmit<T>(lastStepData: T): void;

  handleStepCompleted(data: Partial<FormData>): void;

  formData: Partial<FormData>;
}

export const ServiceRequestFormContext = createContext<ContextProps>({
  handleSubmit(lastStepData) {},
  handleStepCompleted(data) {},
  formData: {},
});
