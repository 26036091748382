import HOExperiencePreferenceRepositoryInterface from '@src/core/domain/HOExperiencePreference/HOExperiencePreferenceRepositoryInterface';

class FetchAllJobTypesHOExperiencePreference {
  private repository: HOExperiencePreferenceRepositoryInterface;

  constructor(HOExperiencePreferenceRepository: HOExperiencePreferenceRepositoryInterface) {
    this.repository = HOExperiencePreferenceRepository;
  }

  execute(): string[] {
    return this.repository.fetchAll();
  }
}

export default FetchAllJobTypesHOExperiencePreference;
