import LocationRepositoryInterface from '@src/core/domain/Locations/LocationRepositoryInterface';
import Locations from '@src/core/domain/Locations/Locations';

class FetchLocations {
  private repository: LocationRepositoryInterface;

  constructor(repository: LocationRepositoryInterface) {
    this.repository = repository;
  }

  execute(locationId?: string): Promise<Locations> {
    if (locationId) {
      return this.repository.fetchChildrenOf(locationId);
    }
    return this.repository.fetchMainLocations();
  }
}

export default FetchLocations;
