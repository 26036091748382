import AnswerOption from '../Answer';

enum AnswerType {
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  MULTIPLE_ANSWERS = 'MULTIPLE_ANSWERS',
  FREE_TEXT = 'FREE_TEXT',
}

type Question = {
  readonly id: string;
  readonly question: string;
  readonly answerType: AnswerType;
  readonly answerOptions: AnswerOption[];
};

export default Question;
export { AnswerType };
