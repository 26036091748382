import React, { useEffect, useState } from 'react';

import CheckProfessionalsWithAutomaticPurchasesFactory from '@src/core/useCases/CheckProfessionalsWithAutomaticPurchases/CheckProfessionalsWithAutomaticPurchasesFactory';
import LocationIdentifier from '@src/core/domain/Locations/LocationIdentifier';
import { ServiceRequestFormState } from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
import { ServiceRequestFormRendererProps } from '@src/ui/apps/ServiceRequest/ServiceRequestFormRenderer';
import { FindingProfessionalsInYourAreaStep } from '@src/ui/apps/ServiceRequest/Steps/FindingProfessionalsInYourAreaStep/FindingProfessionalsInYourAreaStep';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import { FormattedMessage } from 'react-intl';

function sleepPromise(timeToWait: number) {
  return new Promise((resolve) => setTimeout(resolve, timeToWait));
}

function shouldFindAPro(
  state: ServiceRequestFormState,
  prevState: ServiceRequestFormState,
  showFindAPro: boolean
): boolean {
  if (showFindAPro) {
    return true;
  }

  if (state.formData.jobTypeId !== prevState.formData.jobTypeId) {
    return true;
  }

  if (state.formData.postalCode !== prevState.formData.postalCode) {
    return true;
  }

  if (state.formData.secondLevelLocation !== prevState.formData.secondLevelLocation) {
    return true;
  }

  if (state.formData.isDirectoryExperience !== prevState.formData.isDirectoryExperience) {
    return true;
  }

  return false;
}

function canCheckProfessionalAvailability(state: ServiceRequestFormState): boolean {
  return !!(
    state.isFirstQuestion() &&
    (state.formData.postalCode || state.formData.secondLevelLocation) &&
    state.formData.jobTypeId
  );
}

function checkAvailability(props: ServiceRequestFormRendererProps): Promise<URL | boolean | null> {
  const locationIdentifier = LocationIdentifier.fromPostalCodeOrLocationId(
    props.state.formData.postalCode,
    props.state.formData.location
      ? props.state.formData.location.value()
      : props.state.formData.secondLevelLocation
  );

  let isDirectoryExperience;
  if (props.state.formData.isDirectoryExperience !== undefined) {
    isDirectoryExperience = props.state.formData.isDirectoryExperience === 'yes' ? '1' : '0';
  }

  return CheckProfessionalsWithAutomaticPurchasesFactory.create().execute(
    locationIdentifier,
    props.state.formData.jobTypeId,
    props.campaign,
    props.userSource,
    isDirectoryExperience
  );
}

function getJobTypeName(state: ServiceRequestFormState): string {
  if (state.categories.length === 0) {
    return '';
  }

  const categoriesTree = new CategoryGroupTree(state.categories);
  const jobTypeName = state.formData.jobTypeId
    ? categoriesTree.findCategoryById(state.formData.jobTypeId)?.name
    : '';

  return jobTypeName.charAt(0).toUpperCase() + jobTypeName.slice(1).toLowerCase();
}

export function withFAP(
  WrappedRenderer: React.ComponentType<ServiceRequestFormRendererProps>,
  minTimeOnScreen: number
): React.ComponentType<ServiceRequestFormRendererProps> {
  const RendererWithFAP = (props: ServiceRequestFormRendererProps) => {
    const [showFindAPro, setShowFindAPro] = useState(false);
    const [isDraftLead, setIsDraftLead] = useState(false);
    const [prevState, setPrevState] = useState(props.state);

    useEffect(() => {
      if (prevState !== props.state) {
        setPrevState(props.state);
      }

      if (!shouldFindAPro(props.state, prevState, showFindAPro)) {
        return;
      }

      setShowFindAPro(true);

      if (!canCheckProfessionalAvailability(props.state)) {
        return;
      }

      const response = checkAvailability(props);
      const showPageAtLeastSomeTime = sleepPromise(minTimeOnScreen);

      Promise.all([response, showPageAtLeastSomeTime]).then(([response, _]) => {
        if (!response || response === true) {
          setIsDraftLead(response === true);
          setShowFindAPro(false);
        } else {
          window.location.assign(response.toString());
        }
      });
    }, [props.state]);

    if (props.state.isFirstQuestion()) {
      if (showFindAPro) {
        return <FindingProfessionalsInYourAreaStep />;
      }

      const header = props.header || (
        <FormattedMessage
          id="serviceRequestForm.header.hasBusinessAvailable"
          defaultMessage="Hemos encontrado profesionales que hacen trabajos de {jobTypeName} en tu zona"
          description="Mensaje que aparece encima de la primera pregunta en el flujo de FAP"
          values={{ jobTypeName: getJobTypeName(props.state) }}
        />
      );

      if (!isDraftLead) {
        return <WrappedRenderer {...props} header={header} />;
      }
    }
    return <WrappedRenderer {...props} />;
  };
  RendererWithFAP.displayName = 'RendererWithFAP';

  return RendererWithFAP;
}
