import React, { forwardRef } from 'react';
import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import styles from '../../components/Button/Button.module.scss';

export type GoBackProps = {
  goBack: () => void;
  disabled?: boolean;
  datavwo?: string;
  showDesignVariant?: boolean;
  iconImg?: string;
  isHidden?: boolean;
};

const BackButton = forwardRef<HTMLButtonElement, GoBackProps>(
  ({ goBack, disabled, datavwo, showDesignVariant, iconImg, isHidden }: GoBackProps, ref) => {
    return (
      <Button
        type="button"
        variant={ButtonTypes.SECONDARY}
        onClick={goBack}
        disabled={disabled}
        data-testid="backButton"
        data-vwo={datavwo}
        showDesignVariant={showDesignVariant}
        iconImg={iconImg}
        isHidden={isHidden}
        ref={ref}
      >
        {!showDesignVariant ? (
          <FormattedMessage
            id="serviceRequestForm.navigationButtons.back"
            defaultMessage="Volver"
            description="Botón de navegación del formulario hacia atrás"
          />
        ) : (
          <div className={styles.showDesignVariant__buttonText}>
            <FormattedMessage
              id="serviceRequestForm.navigationButtons.back"
              defaultMessage="Volver"
              description="Botón de navegación del formulario hacia atrás"
            />
          </div>
        )}
      </Button>
    );
  }
);

BackButton.displayName = 'BackButton';

export default BackButton;
