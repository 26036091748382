import React, { FunctionComponent } from 'react';
import styles from './Label.module.scss';
import classNames from 'classnames';

type LabelType = {
  children: React.ReactNode;
  htmlFor?: string;
  showDesignVariant?: boolean;
};

const Label: FunctionComponent<LabelType> = ({
  children,
  showDesignVariant,
  ...rest
}: LabelType) => {
  const labelClassName = classNames(styles.label, {
    [styles['label--showDesignVariant']]: showDesignVariant,
    [styles['label--showDesignVariant--postalCode']]:
      showDesignVariant &&
      (rest.htmlFor === 'firstLevelLocation' || rest.htmlFor === 'secondLevelLocation'),
  });
  return (
    <label className={labelClassName} {...rest}>
      {children}
    </label>
  );
};

export default Label;
