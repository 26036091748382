import LocationIdentifier from '@src/core/domain/Locations/LocationIdentifier';
import HttpClient from '@src/core/infrastructure/http';
import { ServiceRequestType } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';

type ProfessionalsAvailabilityResponse = {
  redirectUrl: string;
  isDraftLead: boolean;
};

class CheckProfessionalsWithAutomaticPurchases {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async execute(
    location: LocationIdentifier,
    jobTypeId: string,
    campaignId?: string,
    userSource?: string,
    isDirectoryExperience?: string
  ): Promise<URL | boolean | null> {
    let apiUrl = `${this.baseUrl}/professionals/availability/jobType/${jobTypeId}`;
    const queryParams = {
      ...(campaignId && { campaignId: campaignId }),
      ...(userSource && { userSource: userSource }),
      ...(isDirectoryExperience && { isDirectoryExperience: isDirectoryExperience }),
    };

    if (location.locationId) {
      apiUrl = `${apiUrl}/location/${location.locationId}`;
    } else if (location.postalCode) {
      apiUrl = `${apiUrl}/postalCode/${location.postalCode}`;
    }

    const url = new URLSearchParams(queryParams);
    if (url.toString() !== '') {
      apiUrl = `${apiUrl}?${url.toString()}`;
    }

    const response = await this.httpClient.get<ProfessionalsAvailabilityResponse>(apiUrl);
    const urlString: string = response.data().redirectUrl;
    if (urlString) {
      return new URL(urlString);
    }
    if (response.data()?.isDraftLead) {
      eventBusSingleton.fireEvent(new ServiceRequestType('draft'));
    } else {
      eventBusSingleton.fireEvent(new ServiceRequestType('draft', true));
    }

    return response.data()?.isDraftLead ?? null;
  }
}

export default CheckProfessionalsWithAutomaticPurchases;
