import React from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import styles from './PhotoUpload.module.scss';
import { FormattedMessage } from 'react-intl';
import cameraIcon from './static/camera-o.svg';
import uploadCloud from './static/upload-cloud.svg';

type PhotoUploadProps = {
  disabled?: boolean;
  onDropAccepted<T extends File>(files: T[], event: DropEvent): void;
  onDropRejected?(fileRejections: FileRejection[], event: DropEvent): void;
  showDesignVariant?: boolean;
};

function PhotoUpload(props: PhotoUploadProps): React.ReactElement {
  const ACCEPTED_IMAGE_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
  return (
    <Dropzone
      disabled={props.disabled}
      onDropAccepted={props.onDropAccepted}
      onDropRejected={props.onDropRejected}
      accept={ACCEPTED_IMAGE_FORMATS}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          data-testid="photoUpload"
          className={
            props.showDesignVariant ? styles['photoUpload--showDesignVariant'] : styles.photoUpload
          }
        >
          <input {...getInputProps()} />
          <Button
            variant={ButtonTypes.SECONDARY}
            iconImg={!props.showDesignVariant ? cameraIcon : uploadCloud}
            disabled={props.disabled}
            data-testid="photoUploadButton"
            className={props.showDesignVariant ? 'button--PhotoUploadDesignVariant' : ''}
          >
            {!props.showDesignVariant ? (
              <FormattedMessage
                id="ui.components.photoUpload.buttonText"
                defaultMessage="Adjuntar fotografías <small>(si quieres)</small>"
                description="Botón que al hacer click permite subir fotos"
                values={{
                  small: function small(text: string) {
                    return <span className={styles.photoUpload__optionalText}>{text}</span>;
                  },
                }}
              />
            ) : (
              <FormattedMessage
                id="ui.components.photoUpload.buttonTextNewDesign"
                defaultMessage="Adjuntar fotografías"
                description="Botón que al hacer click permite subir fotos para el nuevo diseño"
              />
            )}
          </Button>
          {props.showDesignVariant ? (
            <div className={styles.photoUpload__informationImagesAllowed}>
              <FormattedMessage
                id="ui.components.photoUpload.infoTextNewDesign"
                defaultMessage="Fotografías PNG o JPEG, máx. 5MB"
                description="Texto informativo sobre el formato y tamaño de las imágenes permitidas"
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </Dropzone>
  );
}

PhotoUpload.defaultProps = {
  disabled: false,
};

export default PhotoUpload;
