import HiddenCategoriesRepositoryInterface from '@src/core/domain/Categories/HiddenCategoriesRepositoryInterface';

class FetchHiddenCategories {
  private repository: HiddenCategoriesRepositoryInterface;

  constructor(HiddenCategoriesRepository: HiddenCategoriesRepositoryInterface) {
    this.repository = HiddenCategoriesRepository;
  }

  execute(): string[] {
    return this.repository.fetchAll();
  }
}

export default FetchHiddenCategories;
