import GetCategoryLegacyId from '@src/core/useCases/Category/GetCategoryLegacyId';
import Factory from '@src/core/Factory';

class GetCategoryLegacyIdFactory {
  private static _instance: GetCategoryLegacyId | undefined;

  static create(): GetCategoryLegacyId {
    if (!this._instance) {
      this._instance = new GetCategoryLegacyId(Factory.newCategoryRepository());
    }

    return this._instance;
  }

  static setInstance(instance: GetCategoryLegacyId): void {
    this._instance = instance;
  }
}

export default GetCategoryLegacyIdFactory;
