import GetLocationLegacyId from '@src/core/useCases/Locations/GetLocationLegacyId';
import Factory from '@src/core/Factory';

class GetLocationLegacyIdFactory {
  private static _instance: GetLocationLegacyId | undefined;

  static create(): GetLocationLegacyId {
    if (this._instance === undefined) {
      this._instance = new GetLocationLegacyId(Factory.newLocationRepository());
    }

    return this._instance;
  }

  static setInstance(instance: GetLocationLegacyId): void {
    this._instance = instance;
  }
}

export default GetLocationLegacyIdFactory;
