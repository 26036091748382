import I18n from '@src/core/I18n';

export function getLocalizedSharePrivate(): string {
  const locale = I18n.locale();
  switch (locale) {
    case 'es-ES':
    case 'it-IT':
    case 'pt-PT':
      return 'yes';
    default:
      return '';
  }
}
