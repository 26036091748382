import Locations from '@src/core/domain/Locations/Locations';
import FetchLocationsFactory from '@src/core/useCases/Locations/FetchLocationsFactory';
import FormField from '@src/ui/components/FormField/FormField';
import Select from '@src/ui/components/Input/Select';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { adaptValidator, notEmptyValidation } from '@src/ui/helpers/form/validations';
import React, { useState, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LocationWithParentSubmitted } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import ValidationError from '@src/ui/helpers/form/ValidationError';

export type LocationSelectInputData = {
  firstLevelLocation?: string;
  secondLevelLocation?: string;
};

type LocationSelectInputProps = {
  form: UseFormMethods<LocationSelectInputData>;
  formData?: LocationSelectInputData;
  setEvent?: (event: unknown) => void;
  showDesignVariant?: boolean;
};

function LocationSelectInput({
  form,
  formData,
  setEvent,
  showDesignVariant,
}: LocationSelectInputProps): React.ReactElement {
  const intl = useIntl();
  const helper = useFormHelper(form);
  const [firstLevelLocations, setFirstLevelLocations] = useState<Locations>(new Locations());
  const [firstLevelLocationId, setFirstLevelLocationId] = useState<string | undefined>(
    formData?.firstLevelLocation
  );
  const [secondLevelLocations, setSecondLevelLocations] = useState<Locations>(new Locations());
  const [secondLevelLocationId, setSecondLevelLocationId] = useState<string | undefined>(
    formData?.secondLevelLocation
  );

  useEffect(() => {
    FetchLocationsFactory.create()
      .execute()
      .then((locations) => {
        setFirstLevelLocations(locations);
        form.setValue('firstLevelLocation', formData?.firstLevelLocation);
      });
  }, []);

  useEffect(() => {
    if (firstLevelLocationId === undefined) return;
    FetchLocationsFactory.create()
      .execute(firstLevelLocationId)
      .then((locations) => {
        setSecondLevelLocations(locations);
        form.setValue('secondLevelLocation', formData?.secondLevelLocation);
      });
  }, [firstLevelLocationId]);

  useEffect(() => {
    if (secondLevelLocations.length() === 1) {
      setSecondLevelLocationId(secondLevelLocations.first().id);
      form.setValue('secondLevelLocation', secondLevelLocations.first().id);
    }
  }, [secondLevelLocations]);

  useEffect(() => {
    if (
      firstLevelLocations.length() > 0 &&
      secondLevelLocations.length() > 0 &&
      firstLevelLocationId &&
      secondLevelLocationId &&
      setEvent
    ) {
      setEvent(
        new LocationWithParentSubmitted(
          firstLevelLocations.getById(firstLevelLocationId),
          secondLevelLocations.getById(secondLevelLocationId)
        )
      );
    }
  }, [secondLevelLocationId, secondLevelLocations]);

  const handleFirstLevelLocationChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value;

    if (value !== firstLevelLocationId) {
      setFirstLevelLocationId(value);
      setSecondLevelLocationId('');
    }
  };

  return (
    <>
      <FormField
        inputId="firstLevelLocation"
        labelText={intl.formatMessage({
          id: 'serviceRequestForm.locationStep.firstLevelLocation.labelText',
          defaultMessage: 'Provincia',
          description: 'Título del campo para seleccionar provincia',
        })}
        errorMessage={helper.errorMessage('firstLevelLocation')}
        showDesignVariant={showDesignVariant}
      >
        <Select
          inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
          name="firstLevelLocation"
          id="firstLevelLocation"
          onChange={handleFirstLevelLocationChange}
          onBlur={handleFirstLevelLocationChange}
          data-testid="firstLevelLocation"
          status={helper.inputStatus('firstLevelLocation')}
          defaultValue={formData?.firstLevelLocation}
          showDesignVariant={showDesignVariant}
        >
          <option value="">
            {intl.formatMessage({
              id: 'serviceRequestForm.locationStep.firstLevelLocation.defaultOptionText',
              defaultMessage: 'Selecciona una provincia',
              description: 'Mensaje indicativo al usuario para que seleccione una provincia',
            })}
          </option>
          {firstLevelLocations.toArray().map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </Select>
        {showDesignVariant && helper.errorMessage && (
          <ValidationError showDesignVariant={showDesignVariant}>
            {helper.errorMessage('firstLevelLocation')}
          </ValidationError>
        )}
      </FormField>

      <FormField
        inputId="secondLevelLocation"
        labelText={intl.formatMessage({
          id: 'serviceRequestForm.locationStep.secondLevelLocation.labelText',
          defaultMessage: 'Población',
          description: 'Título del campo para seleccionar población',
        })}
        errorMessage={helper.errorMessage('secondLevelLocation')}
        showDesignVariant={showDesignVariant}
      >
        <Select
          inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
          name="secondLevelLocation"
          onChange={(event) => setSecondLevelLocationId(event.target.value)}
          onBlur={(event) => setSecondLevelLocationId(event.target.value)}
          data-testid="secondLevelLocation"
          disabled={!firstLevelLocationId}
          status={helper.inputStatus('secondLevelLocation')}
          defaultValue={formData?.secondLevelLocation}
          showDesignVariant={showDesignVariant}
        >
          <option value="">
            {intl.formatMessage({
              id: 'serviceRequestForm.locationStep.secondLevelLocation.defaultOptionText',
              defaultMessage: 'Selecciona una población',
              description: 'Mensaje indicativo al usuario para que seleccione una población',
            })}
          </option>
          {secondLevelLocations.toArray().map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </Select>
        {showDesignVariant && helper.errorMessage && (
          <ValidationError showDesignVariant={showDesignVariant}>
            {helper.errorMessage('secondLevelLocation')}
          </ValidationError>
        )}
      </FormField>
    </>
  );
}

export default LocationSelectInput;
