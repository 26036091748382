import React, { ReactChild, ReactElement } from 'react';
import Alert, { AlertSize, AlertTypes } from '@src/ui/components/Alert/Alert';

type ValidationErrorProps = {
  children: ReactChild;
  showDesignVariant?: boolean;
};

const ValidationError = (props: ValidationErrorProps): ReactElement => (
  <Alert
    variant={AlertTypes.ERROR}
    size={AlertSize.SMALL}
    icon={null}
    showDesignVariant={props.showDesignVariant}
  >
    <span className="error">{props.children}</span>
  </Alert>
);

export default ValidationError;
