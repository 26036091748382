import Factory from '@src/core/Factory';
import FetchServiceRequestQuestions from '@src/core/useCases/Questions/FetchServiceRequestQuestions';

class FetchServiceRequestQuestionsFactory {
  private static _instance: FetchServiceRequestQuestions | undefined;

  static create(): FetchServiceRequestQuestions {
    if (this._instance === undefined) {
      this._instance = new FetchServiceRequestQuestions(Factory.newQuestionsRepository());
    }

    return this._instance;
  }

  static setInstance(instance: FetchServiceRequestQuestions): void {
    this._instance = instance;
  }
}

export default FetchServiceRequestQuestionsFactory;
