import HOExperiencePreferenceRepositoryInterface from '@src/core/domain/HOExperiencePreference/HOExperiencePreferenceRepositoryInterface';
import I18n from '@src/core/I18n';

class HOExperiencePreferenceRepository implements HOExperiencePreferenceRepositoryInterface {
  LOCALE = I18n.intl().locale;
  fetchAll(): string[] {
    return require(`@src/data/jobTypesHOExperiencePreference/${this.LOCALE}.json`);
  }
}

export default HOExperiencePreferenceRepository;
