import StateRepositoryInterface from '@src/core/domain/State/StateRepositoryInterface';
import State from '@src/core/domain/State/State';
import StateReader from '@src/core/infrastructure/State/StateReader';

type StateShape = {
  state_id: number;
  state_name: string;
};

class StateRepository implements StateRepositoryInterface {
  private reader: StateReader;

  constructor(reader: StateReader) {
    this.reader = reader;
  }

  async fetchByPostalCode(postalCode: string): Promise<State> {
    try {
      const item = JSON.parse(await this.reader.fetchByPostalCode(postalCode)) as StateShape;

      return Promise.resolve(new State(item.state_id, item.state_name));
    } catch (err) {
      throw err;
    }
  }
}

export default StateRepository;
