import React from 'react';
import { IMaskMixin } from 'react-imask';

type MaskDefinition = string | RegExp | Mask[];

export interface Mask {
  mask?: MaskDefinition;
  dispatch?: (appended: string, dynamicMasked: string) => number;
}

export interface MaskedInputProps extends Mask {
  onAccept?: (value: string, mask: unknown) => void;
  onComplete?: (value: string, mask: unknown) => void;
  value?: string;
}
export interface ReferenceableComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: (ref: any) => void;
}

export function withMask<ComponentProps>(
  Comp: React.FunctionComponent<ComponentProps & ReferenceableComponent>
): React.FunctionComponent<ComponentProps & MaskedInputProps> {
  return IMaskMixin((innerProps: ComponentProps & ReferenceableComponent) => (
    <Comp {...innerProps} />
  ));
}
