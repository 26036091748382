import { CategoryType } from '@src/core/domain/Categories/Category';
import { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import { Highlighter } from 'react-bootstrap-typeahead';
import React from 'react';
import styles from './JobTypeAutocompleteOption.module.scss';

class JobTypeAutocompleteOption implements AutocompleteOption {
  readonly id: string;
  readonly name: string;
  readonly type: CategoryType;
  readonly categoryName: string;
  readonly showDesignVariant?: boolean;

  constructor(
    id: string,
    name: string,
    type: CategoryType,
    categoryName: string,
    showDesignVariant?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.categoryName = categoryName;
    this.showDesignVariant = showDesignVariant;
  }

  label(search = ''): React.ReactElement {
    const categoryLabel = this.showDesignVariant ? (
      <span>
        <Highlighter search={search}>{this.name}</Highlighter>
      </span>
    ) : (
      <Highlighter search={search}>{this.name}</Highlighter>
    );
    const smallClassName = this.showDesignVariant
      ? `${styles.jobTypeAutocomplete__category} ${styles['jobTypeAutocomplete__category--showDesignVariant']}`
      : styles.jobTypeAutocomplete__category;

    if (this.isCategory()) {
      return categoryLabel;
    }

    return (
      <>
        {categoryLabel}{' '}
        {!this.showDesignVariant ? (
          <small className={smallClassName}>
            en <Highlighter search={search}>{this.categoryName}</Highlighter>
          </small>
        ) : (
          <small className={smallClassName}>{this.categoryName}</small>
        )}
      </>
    );
  }

  labelKey(): string {
    return this.name;
  }

  value(): string {
    return this.id;
  }

  isCategory(): boolean {
    return this.type === CategoryType.CATEGORY;
  }
}

export default JobTypeAutocompleteOption;
