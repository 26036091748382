import I18n from '@src/core/I18n';
import { HttpError } from '@src/core/infrastructure/http';
import { InvalidFormData } from '@src/ui/apps/ServiceRequest/errors/invalidFormData';

export type FormErrors = Record<string, string>;

export const mapError = (error: HttpError): FormErrors => {
  const errorCode = error.code();
  const intl = I18n.intl();
  if (errorCode === InvalidFormData.INVALID_CUSTOMER_NAME) {
    const errorMessage = intl.formatMessage({
      id: 'forms.mapError.invalid_customer_name',
      defaultMessage: 'No es un nombre válido',
      description: 'Mensaje de error cuando el nombre introducido no es válido',
    });

    return { customerName: errorMessage };
  }

  if (errorCode === InvalidFormData.INVALID_CUSTOMER_EMAIL) {
    const errorMessage = intl.formatMessage({
      id: 'forms.mapError.invalid_customer_email',
      defaultMessage: 'No es un email válido',
      description: 'Mensaje de error cuando el email introducido no es válido',
    });

    return { customerEmail: errorMessage };
  }

  if (errorCode === InvalidFormData.INVALID_CUSTOMER_PHONE) {
    const errorMessage = intl.formatMessage({
      id: 'forms.mapError.invalid_customer_phone',
      defaultMessage: 'No es un teléfono válido',
      description: 'Mensaje de error cuando el teléfono introducido no es válido',
    });

    return { customerPhone: errorMessage };
  }
  if (errorCode === InvalidFormData.INVALID_CUSTOMER_EMAIL_WITH_BUSINESS_EMAIL) {
    const errorMessage = intl.formatMessage({
      id: 'forms.mapError.invalid_customer_email_with_business_email',
      defaultMessage: 'Email no válido. Inténtalo de nuevo con otro email',
      description:
        'Mensaje de error cuando el email con el que se hace la petición de presupuesto pertenece a una cuenta profesional',
    });

    return { customerEmail: errorMessage };
  }
  if (errorCode === 'unauthorized') {
    const errorMessage = intl.formatMessage({
      id: 'forms.mapError.invalid_client_id_on_widget',
      defaultMessage:
        'Hay un error de configuración. Ponte en contacto con los responsables de la página.',
      description: 'Mensaje de error cuando el código de cliente no está dado de alta',
    });

    return { unauthorized: errorMessage };
  }

  const errorMessage = intl.formatMessage({
    id: 'forms.mapError.unexpected_error',
    defaultMessage: 'Ha sucedido un error inesperado',
    description:
      'Mensaje de error cuando ocurre un error inesperado en el formulario de petición de presupuesto',
  });

  return { global: errorMessage };
};
