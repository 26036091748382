import EmailAddress from '@src/core/domain/CustomerContactData/EmailAddress';
import InvalidEmailError from '@src/core/domain/CustomerContactData/InvalidEmailError';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import InvalidPhoneNumberError from '@src/core/domain/CustomerContactData/InvalidPhoneNumberError';
import PostalCode from '@src/core/domain/PostalCode/PostalCode';
import InvalidPostalCodeError from '@src/core/domain/PostalCode/InvalidPostalCodeError';
import I18n from '@src/core/I18n';
import PhoneNumber from '@src/core/domain/CustomerContactData/PhoneNumber';
import PhoneNumberValidator from '@src/core/domain/CustomerContactData/PhoneNumberValidator';
import { getLocalizedService } from '../localization/index';
import PostalCodeValidator from '@src/core/domain/PostalCode/PostalCodeValidator';

type ValidationStatus = {
  status: InputStatus;
  message: string;
};

export type Validator<T = string> = (value: T) => ValidationStatus;

const SUCCESS = {
  status: InputStatus.SUCCESS,
  message: '',
};

export const notEmptyValidation: Validator = (value) => {
  if (value && value.trim() !== '') {
    return SUCCESS;
  }

  const intl = I18n.intl();
  const errorMessage = intl.formatMessage({
    id: 'forms.validator.requiredField',
    defaultMessage: 'Debes rellenar este campo',
    description: 'Mensaje de error cuando un campo requerido se deja en blanco',
  });

  return {
    status: InputStatus.ERROR,
    message: errorMessage,
  };
};

export const notEmptyBoolValidation: Validator<boolean | string | string[]> = (value) => {
  const notEmptyArray = !Array.isArray(value) || value.length > 0;
  if (value && notEmptyArray) {
    return SUCCESS;
  }

  const intl = I18n.intl();
  const errorMessage = intl.formatMessage({
    id: 'forms.validator.requiredField',
    defaultMessage: 'Debes rellenar este campo',
    description: 'Mensaje de error cuando un campo requerido se deja en blanco',
  });

  return {
    status: InputStatus.ERROR,
    message: errorMessage,
  };
};

export const emailValidation: Validator = (value) => {
  if (value === '') {
    return SUCCESS;
  }

  try {
    new EmailAddress(value);

    return SUCCESS;
  } catch (error) {
    if (error instanceof InvalidEmailError) {
      const intl = I18n.intl();
      const errorMessage = intl.formatMessage({
        id: 'forms.validator.invalidEmail',
        defaultMessage: 'No es un email válido',
        description: 'Mensaje de error cuando un campo de tipo email no es válido',
      });

      return {
        status: InputStatus.ERROR,
        message: errorMessage,
      };
    }

    throw error;
  }
};

export const phoneNumberValidation: Validator = (value) => {
  const domainPhoneValidator = getLocalizedService<PhoneNumberValidator>('PhoneNumberValidator');
  if (value === '') {
    return SUCCESS;
  }

  try {
    new PhoneNumber(value, domainPhoneValidator);

    return SUCCESS;
  } catch (error) {
    if (error instanceof InvalidPhoneNumberError) {
      const intl = I18n.intl();
      const errorMessage = intl.formatMessage({
        id: 'forms.validator.invalidPhoneNumber',
        defaultMessage: 'El número de teléfono debe contener 9 dígitos',
        description: 'Mensaje de error cuando un campo de tipo teléfono se rellena incorrectamente',
      });

      return {
        status: InputStatus.ERROR,
        message: errorMessage,
      };
    }

    throw error;
  }
};

export const termsAcceptedValidation: Validator<boolean | string> = (value) => {
  if (value) {
    return SUCCESS;
  }

  const intl = I18n.intl();
  const errorMessage = intl.formatMessage({
    id: 'forms.validator.termsNotAccepted',
    defaultMessage: 'Debes aceptar los términos y condiciones',
    description: 'Mensaje de error cuando no se aceptan los términos y condiciones',
  });

  return {
    status: InputStatus.ERROR,
    message: errorMessage,
  };
};

export const postalCodeValidation: Validator = (value) => {
  if (value === '') {
    return SUCCESS;
  }

  try {
    const domainPostalCodeValidator = getLocalizedService<PostalCodeValidator>(
      'PostalCodeValidator'
    );
    new PostalCode(value, domainPostalCodeValidator);

    return SUCCESS;
  } catch (error) {
    const intl = I18n.intl();
    const errorMessage = intl.formatMessage({
      id: 'forms.validator.invalidPostalCode',
      defaultMessage: 'No es un código postal válido',
      description: 'Mensaje de error cuando un código postal no es válido',
    });
    if (error instanceof InvalidPostalCodeError) {
      return {
        status: InputStatus.ERROR,
        message: errorMessage,
      };
    }

    throw error;
  }
};

export const combine = (...validators: Validator[]): Validator => {
  const realValidator: Validator = (value) => {
    let response = SUCCESS;

    for (const validator of validators) {
      response = validator(value);
      if (response.status === InputStatus.ERROR) {
        break;
      }
    }

    return response;
  };

  return realValidator;
};

export function adaptValidator(validator: Validator<string>): (value: string) => true | string {
  return (value: string): true | string => {
    const { message, status } = validator(value);

    return status === InputStatus.SUCCESS || message;
  };
}
