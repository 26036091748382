import CreateServiceRequest from '@src/core/useCases/ServiceRequest/CreateServiceRequest';
import Factory from '@src/core/Factory';

class CreateServiceRequestFactory {
  private static _instance: CreateServiceRequest | undefined;

  static create(): CreateServiceRequest {
    if (this._instance === undefined) {
      this._instance = new CreateServiceRequest(
        Factory.serviceRequestBaseUrl(),
        Factory.newHttpClient()
      );
    }

    return this._instance;
  }

  static setInstance(instance: CreateServiceRequest): void {
    this._instance = instance;
  }
}

export default CreateServiceRequestFactory;
