import React from 'react';
import mem from 'mem';
import FetchAllCategoriesFactory from '@src/core/useCases/Category/FetchAllCategoriesFactory';

export const memoizedCategoryProvider = mem(() => FetchAllCategoriesFactory.create().execute());

export const scrollIntoForm = (formRef: React.RefObject<HTMLElement>): void => {
  if (formRef.current) {
    if (typeof formRef.current.scrollIntoView === 'function') {
      formRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
};

export type scrollerFunction = typeof scrollIntoForm;
