export interface AmplitudeClient {
  logEvent(name: string, data: Record<string, unknown>): void;
  setUserProperties(properties: Record<string, string>): void;
}

type QueuedEvent = {
  name: string;
  data: Record<string, unknown>;
};

type UserPropertiesEvent = Record<string, string>;

class AmplitudeQueuedClient implements AmplitudeClient {
  private queuedUserPropertiesEvents: Array<UserPropertiesEvent> = [];
  private queuedEvents: Array<QueuedEvent> = [];

  constructor(private readonly getClientFn: () => AmplitudeClient | undefined) {}

  public logEvent(name: string, data: Record<string, unknown>): void {
    this.queuedEvents.push({ name: name, data: data });
    this.sendEvents();
  }

  public setUserProperties(properties: UserPropertiesEvent): void {
    this.queuedUserPropertiesEvents.push(properties);
    this.sendEvents();
  }

  private sendEvents() {
    const client = this.getClientFn();
    if (client) {
      this.sendUserPropertiesEvents(client);
      this.sendQueuedEvents(client);
    }
  }

  private sendUserPropertiesEvents(client: AmplitudeClient) {
    this.queuedUserPropertiesEvents.forEach((event) => client.setUserProperties(event));
    this.queuedUserPropertiesEvents = [];
  }

  private sendQueuedEvents(client: AmplitudeClient) {
    this.queuedEvents.forEach((queuedEvent) => client.logEvent(queuedEvent.name, queuedEvent.data));
    this.queuedEvents = [];
  }
}

export default AmplitudeQueuedClient;
