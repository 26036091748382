import React from 'react';
import styles from './Icon.module.scss';

type IconProps = {
  name: string;
  className?: string;
  animate?: boolean;
};

function Icon(props: IconProps): React.ReactElement {
  return (
    <i
      className={`icon icon-${props.name} ${props.className} ${
        props.animate ? styles['icon--animate'] : ''
      }`}
    />
  );
}

Icon.defaultProps = {
  className: '',
};

export default Icon;
