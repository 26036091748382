import ItalianPhoneNumberValidator from '@src/core/domain/CustomerContactData/ItalianPhoneNumberValidator';
import I18n from '@src/core/I18n';
import { DefaultLocationStepRenderer } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/DefaultLocationStepRenderer';
import NDigitsPhoneNumberValidator from '@src/core/domain/CustomerContactData/NDigitsPhoneNumberValidator';
import FiveDigitsPostalCodeValidator from '@src/core/domain/PostalCode/FiveDigitsPostalCodeValidator';
import PortuguesePostalCodeValidator from '@src/core/domain/PostalCode/PortuguesePostalCodeValidator';
import MexicanPhoneNumberValidator from '@src/core/domain/CustomerContactData/MexicanPhoneNumberValidator';
import ChileanLocationStepRenderer from '@src/ui/apps/ServiceRequest/Steps/LocationStep/ChileanLocationStepRenderer';

export type ConfigType = Record<string, Record<string, unknown>>;

export const configuration: ConfigType = {
  'es-ES': {
    phoneMaskProps: { mask: '000 00 00 00' },
    PhoneNumberValidator: new NDigitsPhoneNumberValidator(9),
    LocationStepRenderer: DefaultLocationStepRenderer,
    postalCodeMaskProps: { mask: '00000' },
    PostalCodeValidator: new FiveDigitsPostalCodeValidator(),
  },
  'it-IT': {
    phoneMaskProps: { mask: '00000000[000]' },
    PhoneNumberValidator: new ItalianPhoneNumberValidator(),
    LocationStepRenderer: DefaultLocationStepRenderer,
    postalCodeMaskProps: { mask: '00000' },
    PostalCodeValidator: new FiveDigitsPostalCodeValidator(),
  },
  'pt-PT': {
    phoneMaskProps: { mask: '000 000 000' },
    PhoneNumberValidator: new NDigitsPhoneNumberValidator(9),
    LocationStepRenderer: DefaultLocationStepRenderer,
    postalCodeMaskProps: { mask: '0000-000' },
    PostalCodeValidator: new PortuguesePostalCodeValidator(),
  },
  'es-MX': {
    phoneMaskProps: {
      mask: [
        { mask: '(00) 0000-0000', startsWith: /^(33|81|55|56)/ },
        { mask: '(000) 000-0000', startsWith: /^./ },
      ],
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      dispatch: (appended, dynamicMasked) => {
        const inputValue = dynamicMasked.value + appended;
        const numericCharacters = inputValue.replace(/\D/g, '');

        return dynamicMasked.compiledMasks.find(function (m) {
          return m.startsWith.exec(numericCharacters);
        });
      },
    },
    PhoneNumberValidator: new MexicanPhoneNumberValidator(),
    LocationStepRenderer: DefaultLocationStepRenderer,
    postalCodeMaskProps: { mask: '00000' },
    PostalCodeValidator: new FiveDigitsPostalCodeValidator(),
  },
  'es-CL': {
    phoneMaskProps: { mask: '000 000 000' },
    PhoneNumberValidator: new NDigitsPhoneNumberValidator(9),
    LocationStepRenderer: ChileanLocationStepRenderer,
  },
};

export function getLocalizedService<T>(service: keyof ConfigType, defaultValue?: T): T {
  const locale = I18n.locale();
  const localConfig = configuration[locale];
  if (!localConfig) {
    throw new Error(`unknown locale config for ${locale}`);
  }
  const localService = localConfig[service];
  if (!localService && defaultValue) {
    return defaultValue;
  }

  return localService as T;
}
