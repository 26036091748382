import React, { useState, useEffect, ReactNode } from 'react';
import {
  AsyncTypeahead,
  AllTypeaheadOwnAndInjectedProps,
  TypeaheadMenuProps,
  TypeaheadState,
} from 'react-bootstrap-typeahead';
import { useIntl } from 'react-intl';
import inputStyles from './Input.module.scss';
import './autocomplete.scss';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import cx from 'classnames';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { SearchServiceRequestCategory } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import HTMLInputTypeAttribute from '@src/ui/helpers/form/InputMode';

export interface AutocompleteOption {
  label(search: string | undefined): React.ReactElement;
  labelKey(): string;
  value(): string;
}
type AutocompleteProps = {
  defaultSelected?: AutocompleteOption;
  id?: string;
  onChange: (option?: AutocompleteOption) => void;
  onSearch: (query: string) => Promise<AutocompleteOption[]>;
  /* Either an array of fields in option to search, or a custom filtering callback. */
  // filterBy?: (string[] | ((option: T, props: AllTypeaheadOwnAndInjectedProps<T>) => boolean));
  filterBy?: (
    option: AutocompleteOption,
    props: AllTypeaheadOwnAndInjectedProps<AutocompleteOption>
  ) => boolean;
  placeholder?: string;
  onBlur?: (e: Event) => void;
  inputStatus?: InputStatus;
  renderMenu?: (
    results: AutocompleteOption[],
    menuprops: TypeaheadMenuProps<AutocompleteOption>,
    state: TypeaheadState<AutocompleteOption>
  ) => ReactNode;
  inputModeV?: HTMLInputTypeAttribute;
  showDesignVariant?: boolean;
};

function AsyncAutocomplete({
  id,
  defaultSelected,
  onChange,
  onSearch,
  filterBy,
  placeholder,
  onBlur,
  inputStatus,
  renderMenu,
  inputModeV,
  showDesignVariant,
}: AutocompleteProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<AutocompleteOption[]>(
    defaultSelected ? [defaultSelected] : []
  );
  const [localOptions, setLocalOptions] = useState<AutocompleteOption[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const intl = useIntl();

  useEffect(() => {
    if (defaultSelected) {
      setSelected([defaultSelected]);
    }
  }, [defaultSelected]);
  const className = cx(inputStyles.input, inputStyles[`input--text`], {
    [inputStyles['input--error']]: inputStatus === InputStatus.ERROR,
    [inputStyles['input--success']]: inputStatus === InputStatus.SUCCESS,
    [inputStyles['input--showDesignVariant']]: showDesignVariant,
    [inputStyles['input--error--showDesignVariant']]:
      showDesignVariant && inputStatus === InputStatus.ERROR,
  });

  const inputMode = inputModeV || 'text';
  const timeoutSearchServiceRequest = 3000;
  useEffect(() => {
    let timer;
    if (id === 'jobTypeSearcher' && searchQuery.length >= 3 && !isLoading) {
      timer = setTimeout(() => {
        eventBusSingleton.fireEvent(
          new SearchServiceRequestCategory(timeoutSearchServiceRequest / 1000, searchQuery)
        );
      }, timeoutSearchServiceRequest);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery, isLoading]);

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={isLoading}
      emptyLabel={intl.formatMessage({
        id: 'ui.components.asyncAutocomplete.noResultsFound',
        defaultMessage: 'No se han encontrado resultados',
        description:
          'Texto que aparece si después de realizar la búsqueda no se han encontrado resultados',
      })}
      promptText={''}
      searchText={intl.formatMessage({
        id: 'ui.components.asyncAutocomplete.searching',
        defaultMessage: 'Buscando...',
        description:
          'Texto que aparece cuando un usuario ha introducido un texto y está buscando por detrás',
      })}
      placeholder={placeholder}
      id={showDesignVariant ? `${id}--showDesignVariant` : id}
      minLength={3}
      options={localOptions}
      onSearch={(query) => {
        setIsLoading(true);
        setSearchQuery(query);
        onSearch(query).then((options) => {
          setLocalOptions(options);
          setIsLoading(false);
        });
      }}
      selected={selected}
      multiple={false}
      labelKey={(option: AutocompleteOption) => option.labelKey()}
      onChange={(options: AutocompleteOption[]) => {
        setSelected(options);
        onChange(options[0]);
      }}
      inputProps={{
        className: className,
        inputMode: inputMode,
      }}
      renderMenu={renderMenu}
      renderMenuItemChildren={(option: AutocompleteOption, { text }) => {
        return option.label(text);
      }}
      onBlur={onBlur}
    />
  );
}

export default AsyncAutocomplete;
