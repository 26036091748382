import InvalidPostalCodeError from './InvalidPostalCodeError';
import PostalCodeValidator from './PostalCodeValidator';

class FiveDigitsPostalCodeValidator implements PostalCodeValidator {
  validate(postalCode: string): void {
    const POSTAL_CODE_LENGTH = 5;
    const pattern = new RegExp(`^\\d{${POSTAL_CODE_LENGTH}}$`);

    const isInvalidPostalCode = pattern.test(postalCode);

    if (!isInvalidPostalCode) {
      throw new InvalidPostalCodeError();
    }
  }
}

export default FiveDigitsPostalCodeValidator;
