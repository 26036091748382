import DivisionRepositoryInterface from '@src/core/domain/Divisions/DivisionRepositoryInterface';
import Division from '@src/core/domain/Divisions/Division';
import DivisionReader from '@src/core/infrastructure/Division/DivisionReader';

type DivisionShape = {
  divisionId: number;
  subcategoryId: number;
};

class DivisionRepository implements DivisionRepositoryInterface {
  private reader: DivisionReader;

  constructor(reader: DivisionReader) {
    this.reader = reader;
  }

  async fetchBySubSubCategory(subsubId: string): Promise<Division> {
    try {
      const items = JSON.parse(await this.reader.fetchBySubSub(subsubId)) as DivisionShape;

      return Promise.resolve(new Division(items.divisionId, items.subcategoryId));
    } catch (err) {
      throw err;
    }
  }
}

export default DivisionRepository;
