import React from 'react';

type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

function Image(props: ImageProps): React.ReactElement {
  return <img {...props} src={imageUrl(props)} alt={props.alt} />;
}

function imageUrl(
  props: React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>
): string {
  const publicUrl = window.reactEnv?.PUBLIC_URL ?? process.env.PUBLIC_URL ?? '';
  const src = props.src as string;
  if (src.startsWith('http')) {
    return src;
  }
  if (publicUrl.endsWith('/') && src.startsWith('/')) {
    return publicUrl.slice(0, -1) + src;
  }

  return publicUrl + src;
}

export default Image;
