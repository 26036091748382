import InvalidEmailError from './InvalidEmailError';

class EmailAddress {
  private _email: string;

  constructor(email: string) {
    this.validate(email);

    this._email = email;
  }

  public toString(): string {
    return this._email;
  }

  private validate(email: string): void {
    const isValidEmail = /^(?!-)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

    if (!isValidEmail) {
      throw new InvalidEmailError();
    }
  }
}

export default EmailAddress;
