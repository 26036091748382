import Question, { AnswerType } from '@src/core/domain/Questions/Question';
import AnswerOption from '@src/core/domain/Answer';
import QuestionReader from './QuestionsReader';
import { QuestionsRepositoryInterface } from '@src/core/domain/Questions/QuestionsRepositoryInterface';

type AnswerOptionType = {
  id: string;
  answer: string;
};

type QuestionType = {
  id: string;
  question: string;
  answerType: string;
  answerOptions?: AnswerOptionType[];
};

class QuestionsRepository implements QuestionsRepositoryInterface {
  private _reader: QuestionReader;

  constructor(reader: QuestionReader) {
    this._reader = reader;
  }

  async fetchByJobTypeId(jobTypeId: string): Promise<Question[]> {
    const data = await this._reader.readByJobTypeId(jobTypeId);
    const items = JSON.parse(data) as QuestionType[];

    return Promise.resolve(this.mapMany(items));
  }

  private mapMany(items: QuestionType[]): Question[] {
    return items.map((item) => this.mapQuestion(item));
  }

  private mapQuestion(item: QuestionType): Question {
    return {
      id: item.id,
      question: item.question,
      answerType: item.answerType as AnswerType,
      answerOptions: this.mapAnswerOptions(item.answerOptions || []),
    };
  }

  private mapAnswerOptions(items: AnswerOptionType[]): AnswerOption[] {
    return items.map((item) => ({ id: item.id, answer: item.answer }));
  }
}

export default QuestionsRepository;
