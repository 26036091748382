import CategoryRepositoryInterface from '@src/core/domain/Categories/CategoryRepositoryInterface';

class GetCategoryLegacyId {
  private _repository: CategoryRepositoryInterface;

  constructor(repository: CategoryRepositoryInterface) {
    this._repository = repository;
  }

  execute(uuid: string): Promise<number> {
    return this._repository.getLegacyId(uuid);
  }
}

export default GetCategoryLegacyId;
