import QuestionsReader from './QuestionsReader';
import HttpClient from '../http';

class ApiQuestionReader implements QuestionsReader {
  private baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async readByJobTypeId(jobTypeId: string): Promise<string> {
    const response = await this.httpClient.get(
      `${this.baseUrl}/serviceRequestQuestions/${jobTypeId}`
    );

    return Promise.resolve(JSON.stringify(response.data()));
  }
}

export default ApiQuestionReader;
