import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@src/ui/apps/ServiceRequest/ButtonGroup';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import Button from '@src/ui/components/Button/Button';

type FinalStepFormControlsProps = {
  goBack: () => void;
  isSubmitting: boolean;
  datavwo?: string;
  errors?: Record<string, string>;
  showDesignVariant?: boolean;
};
const FinalStepFormControls = ({
  goBack,
  isSubmitting,
  datavwo,
  showDesignVariant,
}: FinalStepFormControlsProps): React.ReactElement => {
  return (
    <ButtonGroup showDesignVariant={showDesignVariant}>
      <BackButton
        goBack={goBack}
        disabled={isSubmitting}
        datavwo={datavwo}
        showDesignVariant={showDesignVariant}
      />
      <Button
        type="submit"
        isLoading={isSubmitting}
        data-testid="submitButton"
        data-vwo="submitButton"
        showDesignVariant={showDesignVariant}
      >
        <FormattedMessage
          id="serviceRequestForm.customerContactStep.submitButton"
          defaultMessage="Enviar"
          description="Texto que aparece en el botón de enviar formulario"
        />
      </Button>
    </ButtonGroup>
  );
};

export default FinalStepFormControls;
