import Link from '@src/ui/components/Link/Link';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  LocationStepData,
  LocationStepRenderProps,
} from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import { getLocalizedService } from '@src/ui/helpers/localization';
import PostalCodeField from '@src/ui/apps/ServiceRequest/Steps/LocationStep/PostalCodeField';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { Mask } from '@src/ui/helpers/masks/withMask';
import LocationSelectInput from './LocationSelectInput';
import styles from '@src/ui/apps/ServiceRequest/Steps/LocationStep/DefaultLocationStepRenderer.module.scss';

type PostalCodeInputProps = {
  form: UseFormMethods<LocationStepData>;
  formData?: LocationStepData;
  toggle: () => void;
  showDesignVariant?: boolean;
};

function PostalCodeInput({
  form,
  formData,
  toggle,
  showDesignVariant,
}: PostalCodeInputProps): React.ReactElement {
  const postalCodeMaskProps = getLocalizedService<Mask>('postalCodeMaskProps');

  return (
    <>
      <PostalCodeField
        form={form}
        formData={formData}
        showDesignVariant={showDesignVariant}
        {...postalCodeMaskProps}
      />
      <small>
        <Link
          data-testid="revealLocationSearch"
          onClick={(event) => {
            event.preventDefault();
            toggle();
          }}
          showDesignVariant={showDesignVariant}
        >
          <FormattedMessage
            id="serviceRequestForm.locationStep.switchToSearchLocation"
            defaultMessage="¿No lo recuerdas?"
            description="Texto del link que permite al usuario ir a buscar su población si no recuerda su código postal (o equivalente)"
          />
        </Link>
      </small>
    </>
  );
}

export function DefaultLocationStepRenderer({
  form,
  formData,
  setEvent,
  showDesignVariant,
}: LocationStepRenderProps): React.ReactElement {
  const [showLocationSearch, setShowLocationSearch] = useState(
    formData?.firstLevelLocation !== undefined
  );

  return (
    <div className={styles.locationStepRenderer}>
      {!showLocationSearch ? (
        <PostalCodeInput
          form={form}
          formData={formData}
          toggle={() => setShowLocationSearch(true)}
          showDesignVariant={showDesignVariant}
        />
      ) : (
        <LocationSelectInput
          form={form}
          formData={formData}
          setEvent={setEvent}
          showDesignVariant={showDesignVariant}
        />
      )}
    </div>
  );
}
