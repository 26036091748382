import CategoryRepositoryInterface from '@src/core/domain/Categories/CategoryRepositoryInterface';
import Category, { CategoryType } from '@src/core/domain/Categories/Category';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import CategoryReader from './CategoryReader';

type CategoryShape = {
  id: string;
  name: string;
  children: CategoryShape[];
};

type CategoryWithoutChildrenShape = {
  id: string;
  name: string;
  type: CategoryType;
};

class CategoryRepository implements CategoryRepositoryInterface {
  private reader: CategoryReader;

  constructor(reader: CategoryReader) {
    this.reader = reader;
  }

  async fetchAll(): Promise<CategoryGroupTree> {
    const items = JSON.parse(await this.reader.readAll()) as CategoryShape[];
    const categoryList = this.mapMany(items);
    const categoriesTree = new CategoryGroupTree(categoryList);

    return Promise.resolve(categoriesTree);
  }

  async search(query: string): Promise<Category[]> {
    const items = JSON.parse(await this.reader.readSearch(query)) as CategoryWithoutChildrenShape[];
    const response = items.map((category) => {
      return { id: category.id, name: category.name, children: [], type: category.type };
    });

    return Promise.resolve(response);
  }

  async fetchBySlug(slug: string): Promise<Category> {
    try {
      const categoryItem = JSON.parse(
        await this.reader.fetchBySlug(slug)
      ) as CategoryWithoutChildrenShape;

      const categories = JSON.parse(await this.reader.readAll());
      const categoriesTree = new CategoryGroupTree(categories);
      const category =
        categoryItem.type === CategoryType.JOB_TYPE
          ? categoriesTree.findCategoryByJobTypeId(categoryItem.id)
          : undefined;

      const response = {
        id: categoryItem.id,
        name: categoryItem.name,
        children: [],
        type: categoryItem.type,
        parent: category,
      };

      return Promise.resolve(response);
    } catch (err) {
      throw new Error('xxx');
    }
  }

  private map(item: CategoryShape): Category {
    return {
      id: item.id,
      name: item.name,
      children: this.mapMany(item.children),
      type: item.children.length ? CategoryType.CATEGORY : CategoryType.JOB_TYPE,
    };
  }

  private mapMany(items: CategoryShape[]): Category[] {
    return items.map((item) => this.map(item));
  }

  async getLegacyId(categoryId: string): Promise<number> {
    const response = await this.reader.readLegacyId(categoryId);
    return Promise.resolve(response);
  }
}

export default CategoryRepository;
