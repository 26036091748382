import '@src/core/infrastructure/browserPolyfills';

import Application from '@src/core/Application';
import ApplicationConfiguration from '@src/core/ApplicationConfiguration';
import { BrowserStorage } from '@src/core/domain/storage/BrowserStorage';
import CookieBrowserStorage from '@src/core/infrastructure/storage/CookieBrowserStorage';
import { FormRenderingOptions } from '../core/ApplicationConfiguration';
import React from 'react';
import ReactDOM from 'react-dom';
import ServiceRequestForm from '@src/ui/apps/ServiceRequest/ServiceRequestForm';
import ServiceRequestFormRenderer from '@src/ui/apps/ServiceRequest/ServiceRequestFormRenderer';
import { NullBrowserStorage } from '@src/core/domain/storage/NullBrowserStorage';
import { WithIntl } from '@src/core/I18n';
import { withFAP } from '@src/ui/apps/ServiceRequest/hocs/withFAP';
import withForwardServiceRequest from '@src/ui/apps/ForwardServiceRequest/withForwardServiceRequest';
import { EventBus, eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  ServiceRequestCreated,
  sessionIdCreated,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';

export function initServiceRequestForm(
  rootId: string,
  registerCallbacks = defaultEventListenerForHabitissimoDuringParalleleChange
): void {
  const root = document.getElementById(rootId);
  if (root) {
    const serviceSlug = root.dataset.habitissimoServiceSlug;
    const options: Partial<FormRenderingOptions> = {
      enableFindProfessionals: false,
      enableForwardServiceRequest: false,
      clientId: root.dataset.habitissimoClientId,
      extraConfiguration: { disableAutoScroll: true },
    };

    if (serviceSlug) {
      options.serviceSlug = serviceSlug;
    }

    createServiceRequestForm(root, options, new NullBrowserStorage(), registerCallbacks);
  }
}

function defaultEventListenerForHabitissimoDuringParalleleChange(eventBus: EventBus): void {
  eventBus.addListener(ServiceRequestCreated.eventName, (e: ServiceRequestCreated) =>
    window.location.assign(e.createServiceRequestSuccess.url)
  );
}

export function createServiceRequestForm(
  node: HTMLElement,
  options: Partial<FormRenderingOptions>,
  storage: BrowserStorage = new NullBrowserStorage(),
  registerEventListeners = defaultEventListenerForHabitissimoDuringParalleleChange
): void {
  if (node.classList.contains('widget-loaded')) {
    return;
  }

  const applicationConfiguration = new ApplicationConfiguration(
    { ...process.env, ...window.reactEnv },
    window,
    options
  );
  new Application(applicationConfiguration).initialize();

  registerEventListeners(eventBusSingleton);

  const FormRenderer = options.enableFindProfessionals
    ? withFAP(ServiceRequestFormRenderer, 1200)
    : ServiceRequestFormRenderer;

  const ServiceRequestFormComponent = options.enableForwardServiceRequest
    ? withForwardServiceRequest(ServiceRequestForm, storage)
    : ServiceRequestForm;

  ReactDOM.render(
    <React.StrictMode>
      <WithIntl>
        <ServiceRequestFormComponent
          storage={storage}
          configuration={applicationConfiguration.formConfiguration}
          FormRenderer={FormRenderer}
          formData={applicationConfiguration.formConfiguration.customerContactData}
        />
      </WithIntl>
    </React.StrictMode>,
    node,
    () => node.classList.add('widget-loaded')
  );

  const sessionId = storage.getItem('HabitissimoSessionRH') || '';
  eventBusSingleton.fireEvent(new sessionIdCreated(sessionId));
}

initServiceRequestForm('habitissimo-service-request-form');

export { CookieBrowserStorage };
