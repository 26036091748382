import Factory from '@src/core/Factory';
import FetchHiddenCategories from '@src/core/useCases/Category/FetchHiddenCategories';

class FetchHiddenCategoriesFactory {
  private static _instance: FetchHiddenCategories | undefined;

  static create(): FetchHiddenCategories {
    if (this._instance === undefined) {
      this._instance = new FetchHiddenCategories(Factory.newHiddenCategories());
    }

    return this._instance;
  }

  static setInstance(instance: FetchHiddenCategories): void {
    this._instance = instance;
  }
}

export default FetchHiddenCategoriesFactory;
