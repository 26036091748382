import Factory from '@src/core/Factory';
import FetchAllEmailDomains from '@src/core/useCases/EmailDomain/FetchAllEmailDomains';

class FetchAllEmailDomainsFactory {
  private static _instance: FetchAllEmailDomains | undefined;

  static create(): FetchAllEmailDomains {
    if (this._instance === undefined) {
      this._instance = new FetchAllEmailDomains(Factory.newEmailDomainRepository());
    }

    return this._instance;
  }

  static setInstance(instance: FetchAllEmailDomains): void {
    this._instance = instance;
  }
}

export default FetchAllEmailDomainsFactory;
