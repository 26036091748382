import PhoneNumberValidator from '@src/core/domain/CustomerContactData/PhoneNumberValidator';

class PhoneNumber {
  private _phoneNumber: string;

  constructor(phoneNumber: string, phoneValidator: PhoneNumberValidator) {
    phoneValidator.validate(phoneNumber);

    this._phoneNumber = phoneNumber;
  }

  public toString(): string {
    return this._phoneNumber;
  }
}

export default PhoneNumber;
