import React, { useEffect, useState } from 'react';
import styles from './NewDesignProgressBar.module.scss';
import ProgressBar from '@src/ui/components/ProgressBar/ProgressBar';
import { FormattedMessage } from 'react-intl';
import ArrowIcon from '../Button/static/arrow-left.svg';
import Logo from './static/logo-habitissimo.svg';
import Image from '@src/ui/components/Image/Image';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';

type NewDesignProgressBarProps = {
  showDesignVariant?: boolean;
  progress: number;
  currentStep: number;
  totalSteps: number;
  goBack: () => void;
  showSharePrivateServiceStep?: boolean;
  onJobTypeStepBackButtonClick: () => void;
};

function NewDesignProgressBar({
  showDesignVariant,
  progress,
  currentStep,
  totalSteps,
  goBack,
  showSharePrivateServiceStep,
  onJobTypeStepBackButtonClick,
}: NewDesignProgressBarProps): React.ReactElement {
  const [hidde, setHidde] = useState<boolean>(false);

  useEffect(() => {
    eventBusSingleton.addListener('jobTypeAutocompleteVisible', () => {
      setHidde(true);
    });

    eventBusSingleton.addListener('jobTypeAutocompleteHidden', () => {
      setHidde(false);
    });
  }, []);

  const steps = showSharePrivateServiceStep ? totalSteps + 1 : totalSteps;
  const getTitleId = () => {
    if (currentStep >= 3 && currentStep < steps - 2) {
      return 'serviceRequestForm.newDesignProgressBar.questionStep.title';
    } else {
      switch (currentStep) {
        case 1:
          return 'serviceRequestForm.newDesignProgressBar.jobTypeStep.title';
        case 2:
          return 'serviceRequestForm.newDesignProgressBar.locationStep.title';
        case steps - 2:
          return 'serviceRequestForm.newDesignProgressBar.descriptionStep.title';
        case steps - 1:
          return 'serviceRequestForm.newDesignProgressBar.customerContactStep.title';
      }
    }
  };
  const getTitleDefaultMessage = () => {
    if (currentStep >= 3 && currentStep < steps - 2) {
      return 'Tan solo unas preguntas más';
    } else {
      switch (currentStep) {
        case 1:
          return '¡Sólo te llevará un minuto!';
        case 2:
          return 'Encuentra expertos cerca de ti';
        case steps - 2:
          return '¡Estás a un paso!';
        case steps - 1:
          return '¡Último paso!';
      }
    }
  };

  const getBodyId = () => {
    if (currentStep >= 3 && currentStep < steps - 2) {
      return 'serviceRequestForm.newDesignProgressBar.questionStep.body';
    } else {
      switch (currentStep) {
        case 1:
          return 'serviceRequestForm.newDesignProgressBar.jobTypeStep.body';
        case 2:
          return 'serviceRequestForm.newDesignProgressBar.locationStep.body';
        case steps - 2:
          return 'serviceRequestForm.newDesignProgressBar.descriptionStep.body';
        case steps - 1:
          return 'serviceRequestForm.newDesignProgressBar.customerContactStep.body';
      }
    }
  };
  const getBodyDefaultMessage = () => {
    if (currentStep >= 3 && currentStep < steps - 2) {
      return 'Añade los detalles de tu proyecto y obtén presupuestos personalizados';
    } else {
      switch (currentStep) {
        case 1:
          return 'Responde algunas preguntas y consigue presupuestos gratis y sin compromiso';
        case 2:
          return 'Conecta con los mejores profesionales de tu zona, sin compromiso';
        case steps - 2:
          return 'Amplía tu solicitud con más detalles o fotografías del proyecto (si quieres) ';
        case steps - 1:
          return 'Un máximo de  4 profesionales de tu zona te podrán enviar su presupuesto para que puedas comparar';
      }
    }
  };
  return (
    <div className={styles.NewDesignProgressBar} data-testid="NewDesignProgressBar">
      {currentStep === 1 && (
        <BackButton
          isHidden={hidde}
          goBack={onJobTypeStepBackButtonClick}
          showDesignVariant={showDesignVariant}
          iconImg={ArrowIcon}
        />
      )}

      {currentStep > 1 && (
        <BackButton goBack={goBack} showDesignVariant={showDesignVariant} iconImg={ArrowIcon} />
      )}

      <Image src={Logo} className={styles.NewDesignProgressBar__logo} />

      <div className={styles.NewDesignProgressBar__title}>
        <FormattedMessage
          id={getTitleId()}
          defaultMessage={getTitleDefaultMessage()}
          description="Título para cada step del progressBar del nuevo diseño"
        />
      </div>
      <div className={styles.NewDesignProgressBar__body}>
        <FormattedMessage
          id={getBodyId()}
          defaultMessage={getBodyDefaultMessage()}
          description="Body para cada step del progressBar del nuevo diseño"
        />
      </div>
      <ProgressBar
        progress={progress}
        showDesignVariant={showDesignVariant}
        currentStep={currentStep}
      />
    </div>
  );
}

export default NewDesignProgressBar;
