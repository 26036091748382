import HttpClient from '@src/core/infrastructure/http';

type ForwardServiceRequestToBusinessResponseJSON = {
  numberOfTradesThatCanBuyServiceRequest: number;
};

class ForwardServiceRequestToBusiness {
  constructor(private serverUrl: string, private httpClient: HttpClient) {}

  async execute(businessId: string, serviceRequestId: string): Promise<number> {
    const response = await this.httpClient.post<ForwardServiceRequestToBusinessResponseJSON>(
      `${this.serverUrl}/serviceRequest/${serviceRequestId}/trades`,
      {
        tradeId: businessId,
      }
    );
    return response.data().numberOfTradesThatCanBuyServiceRequest;
  }
}

export default ForwardServiceRequestToBusiness;
